import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

type Props = {
  onClick?: () => void;
  disabled?: boolean;
  variant?: string;
  loading?: boolean;
  buttonText: string;
};

function GlobalButton({
  onClick,
  disabled = false,
  loading = false,
  buttonText,
  variant = 'primary'
}: Props) {
  return (
    <Button variant={variant} style={{ width: '100%' }} onClick={onClick} disabled={disabled}>
      {loading ? <Spinner animation="border" /> : buttonText}
    </Button>
  );
}

export default GlobalButton;
