import { useLocation } from '@tanstack/react-location';
import React, { useEffect, useState } from 'react';
import { useAccessTokenByCodeMutation } from '../mutations/auth.mutation';

export default function Authorized() {
  const location = useLocation();
  const [code, setCode] = useState('');
  const [timer, setTimer] = useState(5);
  const { mutate: getAccessToken, isSuccess, isLoading, isError } = useAccessTokenByCodeMutation();

  useEffect(() => {
    if (!code) {
      const { code: queryCode } = location.current.search as { code: string };
      if (queryCode) {
        setCode(queryCode);
      }
    }
  }, [code, location]);

  // redirect to home page after 5 seconds
  useEffect(() => {
    if (isSuccess) {
      const interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 0) {
            location.history.replace('/');
            return prev;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isSuccess, location.history]);

  const handleAuthorize = () => {
    void getAccessToken(code);
  };
  const handleDecline = () => {
    location.history.replace('/');
  };

  return (
    <div className="mt-5">
      {code && !isLoading && !isSuccess && !isError && (
        <div className="d-flex flex-column justify-content-center align-items-center border rounded-2 p-5 mx-auto alert alert-primary">
          <h2>Authorize with FreshBooks</h2>
          <div className="d-flex gap-2">
            <button className="btn btn-primary" onClick={handleAuthorize}>
              Authorize
            </button>
            <button className="btn btn-dark" onClick={handleDecline}>
              Decline
            </button>
          </div>
        </div>
      )}
      {isSuccess && (
        <div>
          <h2>Authorization Successful!</h2>
          <p>Your authorization code is: {code}</p>
          <p>
            You will be redirected to home page in <strong>{timer}</strong> or manually close this
            window
          </p>
        </div>
      )}

      {isError && (
        <div>
          <h2>Authorization Failed!</h2>
          <p>There was an error authorizing your account.</p>
          <p>Please try again.</p>
        </div>
      )}

      {isLoading && (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h2>Authorizing...</h2>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
}
