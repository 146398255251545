import React, { useEffect } from 'react';
import { useImportFreshBooksClientsMutation } from '../../mutations/client.mutation';
import toast from 'react-hot-toast';
import { string } from 'yup';

export default function ImportFreshBooksClientsButton() {
  const { mutateAsync: importFreshBooksClients, isLoading } = useImportFreshBooksClientsMutation();
  const importFreshBooksClientsHandler = () => {
    toast.promise(importFreshBooksClients(), {
      loading: 'Importing FreshBooks Clients...',
      success: (data) => {
        // validate data.data if url is present
        const isUrl = string().url().isValidSync(data.data);
        isUrl && window.open(data.data, '_blank');
        return isUrl ? `Redirecting...` : data.data;
      },
      error: 'Error Importing FreshBooks Clients'
    });
  };

  useEffect(() => {});
  return (
    <button
      disabled={isLoading}
      className="btn btn-primary w-50"
      onClick={importFreshBooksClientsHandler}>
      Import FreshBooks Clients
    </button>
  );
}
