import { useQuery } from '@tanstack/react-query';
import { checkAuth } from '../requests/auth.request';

export const useAuthCheckQuery = (enabled = false) => {
  return useQuery({
    enabled,
    queryKey: ['authCheck'],
    queryFn: async () => await checkAuth(),
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false
  });
};
