import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { Alert, Button, Col, Collapse, Form, Row, Stack } from 'react-bootstrap';
import { fetchTicketsByPlates, updatePlateInDataBase } from '../Functions';
import { addPlateToClient, updatePlateFromClient } from '../ReduxSlices/clientsSlice';
import { setEditingPlate } from '../ReduxSlices/platesSlice';
import { Client, MonitoringList, MonitoringRange, Plate } from '../Types';
import GlobalButton from './Buttons/GlobalButton';
import CustomDatePicker from './FormComponents/CustomDatePicker';
import { useAppDispatch, useAppSelector } from '../hooks';
import { useMonitoringListQuery } from '../queries/monitoring.query';
import { customFetch } from '../helpers/customFetch';
import { PlateResponseType } from '../schemas/plate-schema';
import Select from 'react-select';

const initialNewPlateState = {
  open: false,
  plateNumber: '',
  monitoringThisPlate: false,
  startDate: '',
  endDate: '',
  state: { label: 'New York', value: 'NY' }
};

const states = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Guam', value: 'GU' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Trust Territories', value: 'TT' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Virgin Islands', value: 'VI' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' }
];

const returnFormattedDateString = (dateInstance: Date) => dateInstance.toISOString().slice(0, 10);
const currentDate = new Date();
const oneMonthPrior = new Date(currentDate);
oneMonthPrior.setMonth(currentDate.getMonth() - 1);

const plateReducer = (state, action) => {
  const plate = action.payload;
  switch (action.type) {
    case 'toggleOpen':
      return state.open ? initialNewPlateState : { ...state, open: !state.open };
    case 'stagePlateEdit': {
      let selectedState = states.find((s) => s.value === plate.state);
      if (selectedState === undefined) {
        selectedState = null;
      }

      return {
        ...initialNewPlateState,
        plateNumber: plate.plateNumber,
        monitoringThisPlate: !!plate.monitoringRange,
        startDate: plate.monitoringRange
          ? returnFormattedDateString(new Date(plate.monitoringRange.startDate))
          : '',
        endDate: plate.monitoringRange
          ? returnFormattedDateString(new Date(plate.monitoringRange.endDate))
          : '',
        state: selectedState
      };
    }
    case 'setPlateNumber':
      return { ...state, plateNumber: action.payload };
    case 'setState':
      return { ...state, state: action.payload };
    case 'toggleMonitoringThisPlate':
      return {
        ...state,
        monitoringThisPlate: !state.monitoringThisPlate,
        startDate: !state.monitoringThisPlate ? returnFormattedDateString(oneMonthPrior) : '',
        endDate: !state.monitoringThisPlate ? '2999-01-01' : ''
      };
    case 'setStartDate':
      return { ...state, startDate: action.payload };
    case 'setEndDate':
      return { ...state, endDate: action.payload };
    case 'reset':
      return { ...initialNewPlateState };
  }
};

interface INewPlateCollapseProps {
  delinquent?: string;
  client: Client;
  monitoringList: MonitoringList;
  disabled?: boolean;
}
function NewPlateCollapse(props: INewPlateCollapseProps) {
  const { monitoringList, client, disabled } = props;
  const [chargeCC, setChargeCC] = useState(false);
  const [newPlateState, dispatch] = useReducer(plateReducer, initialNewPlateState);
  const reduxDispatch = useAppDispatch();
  const { data } = useMonitoringListQuery({ enabled: true });
  const { unassignedMonitoringItem } = data ?? {};
  const editingPlate = useAppSelector((state) => state.plates.editingPlate);

  const createMonitoringRange = (e: any) => {
    e.preventDefault();
    if (!newPlateState.plateNumber) {
      return;
    }
    customFetch({
      endpoint: '/MonitoringRange',
      config: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          startDate: new Date(newPlateState.startDate ? newPlateState.startDate : Date.now()),
          endDate: new Date(newPlateState.endDate ? newPlateState.endDate : '2999-01-01')
        })
      },
      callback: (r: MonitoringRange) => (editingPlate ? updatePlate(r.id) : createNewPlate(r.id))
    });
  };
  const createNewPlate = async (monitoringRangeId: string) => {
    const callback = (result: PlateResponseType) => {
      reduxDispatch(addPlateToClient(result.data));
      dispatch({ type: 'toggleOpen' });
      fetchTicketsByPlates([newPlateState.plateNumber], {
        clientId: result.data.clientId,
        state: newPlateState.state?.value
      });
    };
    await customFetch({
      endpoint: '/Plate',
      callback: callback,
      config: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          plateNumber: newPlateState.plateNumber,
          monitoringRangeId: monitoringRangeId,
          clientId: client.id,
          state: newPlateState.state?.value
        })
      }
    });
  };

  const updatePlate = (monitoringRangeId: string) => {
    updatePlateInDataBase(
      {
        ...editingPlate,
        plateNumber: newPlateState.plateNumber,
        monitoringRangeId: monitoringRangeId,
        state: newPlateState.state?.value
      },
      (modifiedPlate: Plate) => {
        reduxDispatch(updatePlateFromClient(modifiedPlate));
        handleResetPlateState();
      }
    );
  };

  const handleFormSubmit = (e?: any) => {
    e?.preventDefault();
    newPlateState.monitoringThisPlate
      ? createMonitoringRange(e)
      : editingPlate
      ? updatePlate('')
      : createNewPlate('');
  };

  useEffect(() => {
    if (editingPlate) {
      dispatch({ type: 'stagePlateEdit', payload: editingPlate });
    }
  }, [editingPlate]);
  useEffect(() => {
    dispatch({ type: 'reset' });
  }, []);

  const handleStateChange = (value) => {
    dispatch({ type: 'setState', payload: value });
  };

  const handleResetPlateState = () => {
    reduxDispatch(setEditingPlate(null));
    dispatch({ type: 'reset' });
  };

  const disabledSavePlateButton = useMemo(() => {
    return (
      !newPlateState.plateNumber ||
      (newPlateState.monitoringThisPlate && !client.recurringInvoiceFreshbooksId && !chargeCC)
    );
  }, [
    chargeCC,
    client.recurringInvoiceFreshbooksId,
    newPlateState.monitoringThisPlate,
    newPlateState.plateNumber
  ]);
  return disabled ? null : (
    <>
      {!newPlateState.open && !editingPlate && (
        <Button
          onClick={() => dispatch({ type: 'toggleOpen' })}
          // disabled={!!props.delinquent}
          disabled={false}>
          {/* {props.delinquent
            ? "New plates cannot be added until a working CC is added"
            : "Add Plate"} */}
          New Plate
        </Button>
      )}

      <Collapse in={newPlateState.open || !!editingPlate}>
        <Form onSubmit={handleFormSubmit}>
          <Stack gap={1}>
            <Form.Group as={Row} className="mt-2">
              <Col xs="6">
                <Form.Control
                  className="me-auto"
                  autoFocus
                  placeholder="Enter Plate Number"
                  value={newPlateState.plateNumber}
                  onChange={(e) => dispatch({ type: 'setPlateNumber', payload: e.target.value })}
                />
              </Col>
              <Col xs="6">
                <Select
                  options={states}
                  placeholder="Select state"
                  isClearable={true}
                  onChange={handleStateChange}
                  value={newPlateState.state}
                />
              </Col>
            </Form.Group>
            <Stack direction="horizontal" gap={3}>
              <Form.Group
                className={
                  'text-center align-items-center' +
                  (monitoringList && monitoringList?.id !== unassignedMonitoringItem.id
                    ? ''
                    : ' invisible')
                }>
                <Form.Check
                  label="Monitor"
                  type="switch"
                  checked={newPlateState.monitoringThisPlate}
                  onChange={() => dispatch({ type: 'toggleMonitoringThisPlate' })}
                />
              </Form.Group>
              <Stack
                gap={2}
                direction="horizontal"
                className={newPlateState.monitoringThisPlate ? '' : 'invisible'}>
                <Form.Label className="mb-0">From</Form.Label>
                <CustomDatePicker
                  onChange={(e) =>
                    dispatch({
                      type: 'setStartDate',
                      payload: e.target.value
                    })
                  }
                  value={newPlateState.startDate}
                />
              </Stack>
              <Stack
                gap={2}
                direction="horizontal"
                style={{ marginBottom: 0 }}
                className={newPlateState.monitoringThisPlate ? '' : 'invisible'}>
                <Form.Label className="mb-0">To</Form.Label>
                <CustomDatePicker
                  onChange={(e) =>
                    dispatch({
                      type: 'setEndDate',
                      payload: e.target.value
                    })
                  }
                  value={newPlateState.endDate}
                />
              </Stack>
            </Stack>

            {newPlateState.monitoringThisPlate && !client.recurringInvoiceFreshbooksId && (
              <Alert variant="warning">
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 700,
                    marginBottom: 0
                  }}>
                  Warning!
                </p>
                <p>
                  This client does not yet have a recurring invoice in our system. If they do not
                  have a recurring invoice set up in freshbooks, {''}
                  <strong>${monitoringList?.amount}</strong> will be charged to their card.
                </p>
                <Form.Check
                  label={
                    <p className="mb-0">
                      Charge <strong>${monitoringList?.amount}</strong> to {`Client's`} Credit Card
                    </p>
                  }
                  type="switch"
                  checked={chargeCC}
                  onChange={() => setChargeCC(!chargeCC)}
                />
              </Alert>
            )}
            <Stack direction="horizontal" gap={5}>
              <GlobalButton
                onClick={() =>
                  editingPlate ? handleResetPlateState() : dispatch({ type: 'toggleOpen' })
                }
                buttonText="Cancel"
                variant="secondary"
              />
              <GlobalButton
                disabled={disabledSavePlateButton}
                onClick={handleFormSubmit}
                buttonText={(editingPlate ? 'Save' : 'Add') + ' Plate'}
              />
            </Stack>
          </Stack>
          <br />
        </Form>
      </Collapse>
    </>
  );
}

export default NewPlateCollapse;
