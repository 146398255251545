import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Stack from 'react-bootstrap/Stack';
import { customAxiosFetch } from '../helpers/customFetch';
import { SavedCreditCards } from '../Types';

interface Props {
  cardPointeProfileId: string;
  clientId: string;
}
export default function ClientSavedCreditCards({ cardPointeProfileId, clientId }: Props) {
  const [savedCreditCards, setsavedCreditCards] = useState<SavedCreditCards[]>([]);
  const [show, setShow] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState('');

  useEffect(() => {
    getSavedCreditCards();
  }, []);

  const getSavedCreditCards = () => {
    if (cardPointeProfileId) {
      customAxiosFetch<SavedCreditCards[]>({
        endpoint: `/payment/saved-credit-cards/card-pointe-profileId/${cardPointeProfileId}`,
        config: { method: 'GET' },
        callback: (result) => {
          setsavedCreditCards(result);
        }
      });
    }
  };

  const handleSetDefaultCard = (index: number) => {
    customAxiosFetch<{
      success: boolean;
    }>({
      endpoint: `/payment/set-default-card/card-pointe-profileId/${cardPointeProfileId}?index=${index}`,
      config: { method: 'POST' },
      callback: (result) => {
        if (!result?.success) return;
        getSavedCreditCards();
      }
    });
  };

  const handleDeleteCard = () => {
    setShow(false);
    customAxiosFetch<{
      success: boolean;
    }>({
      endpoint: `/Client/${clientId}/RemoveCreditCard/${selectedAccountId}`,
      config: { method: 'DELETE' },
      callback: (result) => {
        if (!result?.success) return;
        getSavedCreditCards();
      }
    });
  };

  const handleCloseModal = () => {
    setShow(false);
    setSelectedAccountId('');
  };
  const handleOpenModal = (accountId: string) => {
    setShow(true);
    setSelectedAccountId(accountId);
  };

  return (
    <>
      <Modal show={show} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete a card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">Are you sure to permanently delete a card?</div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal}>No</Button>
          <Button onClick={handleDeleteCard} variant="danger">
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Stack className="mt-4">
        <h2>Saved CreditCards</h2>
        <Stack direction="vertical">
          {savedCreditCards.length > 0
            ? savedCreditCards.map((item, index) => {
                return (
                  <Stack direction="horizontal" key={item.token}>
                    <span className="col-md-2">{item.token}</span>
                    {!item.token.includes('*') && (
                      <Button
                        variant="link"
                        onClick={() => {
                          handleSetDefaultCard(index);
                        }}>
                        Set default
                      </Button>
                    )}
                    <Button variant="link" onClick={() => handleOpenModal(item.accountId)}>
                      Delete
                    </Button>
                  </Stack>
                );
              })
            : 'No saved cards'}
        </Stack>
      </Stack>
    </>
  );
}
