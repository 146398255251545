import React from 'react';
import Row from 'react-bootstrap/Row';
import PlateCard from './PlateCard';
import SelectablePlateCard from './SelectablePlateCard';
import { MonitoringRange, Plate } from '../Types';

interface Props {
  plates: Array<Plate & { monitoringRange: MonitoringRange }>;
  selectingMultiple: boolean;
  selectedPlateNumbers: string[];
  togglePlateNumberSelect: (_: string) => void;
  showArchived: boolean;
}

function PlatesGroup({
  plates,
  showArchived,
  selectingMultiple,
  selectedPlateNumbers,
  togglePlateNumberSelect
}: Props) {
  const filteredPlates = showArchived ? plates : plates.filter((plate) => !plate.archivedAt);

  return (
    <Row xs={2} md={3}>
      {filteredPlates.map((plate) =>
        selectingMultiple ? (
          <SelectablePlateCard
            key={plate.id}
            plate={plate}
            selectedPlateNumbers={selectedPlateNumbers}
            togglePlateNumberSelect={togglePlateNumberSelect}
          />
        ) : (
          <PlateCard key={plate.id} plate={plate} />
        )
      )}
    </Row>
  );
}

export default PlatesGroup;
