import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import { addNewClient } from '../ReduxSlices/clientsSlice';
import ClientForm from './ClientForm';
import { useAppDispatch } from '../hooks';
import { useMonitoringListQuery } from '../queries/monitoring.query';
import { useCreateClientMutation } from '../mutations/client.mutation';
import toast from 'react-hot-toast';

function NewClient() {
  const dispatch = useAppDispatch();
  const { data: monitoringLists } = useMonitoringListQuery({ enabled: true });
  const { mutateAsync: createClient, isLoading } = useCreateClientMutation();

  const defaultFormData = {
    name: '',
    emailAddress: '',
    phoneNumber: '',
    alternateEmailAddress: '',
    alternatePhoneNumber: '',
    freshbooksId: null,
    cardPointeProfileId: '',
    monitoringListId: monitoringLists?.unassignedMonitoringItem?.id ?? ''
  };

  const [formData, setFormData] = useState({ ...defaultFormData });

  const clearForm = () => {
    setFormData({ ...defaultFormData });
  };

  const handleDataChange = (key: string, value: string) =>
    setFormData({ ...formData, [key]: value });

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (formData.monitoringListId === 'NewMonitoringList') {
      return;
    }
    const payload = {
      ...formData,
      monitoringListId: formData.monitoringListId
        ? formData.monitoringListId
        : monitoringLists?.unassignedMonitoringItem.id
    };
    toast.promise(
      createClient(payload, {
        onSuccess(data) {
          dispatch(addNewClient(data));
          clearForm();
        }
      }),
      {
        loading: 'Creating Client...',
        success: 'Client Created',
        error: 'Error Creating Client'
      }
    );
  };

  return (
    <Accordion
      defaultActiveKey={'0'}
      onSelect={(e) => {
        e !== '1' && clearForm();
      }}>
      <Accordion.Item eventKey="1">
        <Accordion.Header>New Client Form</Accordion.Header>
        <Accordion.Body>
          <Container className="border rounded p-2">
            <ClientForm
              isLoading={isLoading}
              formData={formData}
              handleDataChange={handleDataChange}
              handleSubmit={handleSubmit}
              clearForm={clearForm}
              unassignedMonitoringListId={monitoringLists?.unassignedMonitoringItem?.id}
            />
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default NewClient;
