import React, { memo } from 'react';
import Form from 'react-bootstrap/Form';
import { FormikProps } from 'formik';
import { BulkIgnoreTicketsSchema } from '../schemas/ticket.schema';

export const addBulkIgnoreTicketsFormInitialValue = {
  csvFile: null
};

type ICsvFileUploadFormProps = {
  form: FormikProps<BulkIgnoreTicketsSchema>;
};

const AddBulkIgnoreTicketsForm = ({ form }: ICsvFileUploadFormProps) => {
  function validateUploadedFile(event) {
    const fileInput = event.target as HTMLInputElement;
    const files = fileInput.files;

    if (files && files.length > 0) {
      const selectedFile = files[0];
      const isCSVFile = selectedFile.type === 'text/csv';

      if (isCSVFile) {
        form.setFieldValue('csvFile', selectedFile);
        form.setFieldError('csvFile', undefined);
      } else {
        form.setFieldError('csvFile', 'Please upload a CSV file');
        form.setFieldValue('csvFile', null);
      }
    } else {
      // Clear the field value if no file is selected
      form.setFieldValue('csvFile', null);
    }
  }

  return (
    <Form
      encType="multipart/form-data"
      onSubmit={(e) => {
        if (e.currentTarget.checkValidity() === false) {
          form.handleSubmit(e);
        }
      }}>
      <Form.Group controlId="csvFile-input">
        <Form.Label>Csv File</Form.Label>
        <Form.Control
          required
          type="file"
          name="csvFile"
          accept=".csv"
          onChange={validateUploadedFile}
          onBlur={form.handleBlur}
          isInvalid={form.touched.csvFile && !form.values.csvFile && !!form.errors.csvFile}
        />
        <Form.Control.Feedback type="invalid">
          {form.touched.csvFile && form.errors.csvFile && form.errors.csvFile.toString()}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};

export default memo(AddBulkIgnoreTicketsForm);
