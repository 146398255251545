import { PayloadAction, createSlice } from '@reduxjs/toolkit';
export const SUCCESS_SLICE_NAME = 'success_slice';

interface SuccessReduxState {
  success: string | null;
  isOpen: boolean;
}

const initialState: SuccessReduxState = {
  success: null,
  isOpen: false
};

export const successSlice = createSlice({
  name: 'success',
  initialState: { ...initialState },
  reducers: {
    setSuccess: (state, action: PayloadAction<string>) => {
      state.success = action.payload;
      state.isOpen = true;
    },
    clearSuccess: () => {
      return initialState;
    }
  }
});

export const { setSuccess: setSuccessAction, clearSuccess: clearSuccessAction } =
  successSlice.actions;
export default successSlice.reducer;
