import React, { useCallback, useState } from 'react';
import SendEmailReportModal from '../SendEmailReportModal';
import toast from 'react-hot-toast';
import { EnvelopeFill, Send, SendX } from 'react-bootstrap-icons';

export default function SendEmailReport({ emailAddress }: { emailAddress: string }) {
  const [showModal, setShowModal] = useState(false);

  const sendEmailReport = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onSuccess = useCallback(() => {
    toast.success('Report sent successfully');
  }, []);
  return (
    <>
      {emailAddress && (
        <a
          title="Send Report to this Email"
          onClick={sendEmailReport}
          href="#send-report"
          className="text-decoration-none ">
          <EnvelopeFill /> {emailAddress} <Send className="text-info" />
        </a>
      )}
      {showModal && (
        <SendEmailReportModal
          show={showModal}
          onCloseModal={handleCloseModal}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
}
