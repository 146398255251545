import React from 'react';
import FormControl from 'react-bootstrap/FormControl';
import { useDebouncedCallback } from 'use-debounce';
import { setClientSearchTerm } from '../ReduxSlices/clientsSlice';
import { fetchClients } from '../Functions';
import { useAppDispatch, useAppSelector } from '../hooks';

function ClientsSearchAndFilter() {
  const searchTerm = useAppSelector((state) => state.clients.searchTerm);
  const dispatch = useAppDispatch();

  const fetchWithFilter = useDebouncedCallback(() => fetchClients(1, searchTerm), 1000);

  return (
    <FormControl
      placeholder="Search for Clients"
      type="text"
      value={searchTerm}
      onChange={(event) => {
        dispatch(setClientSearchTerm(event.target.value));
        fetchWithFilter();
      }}
    />
  );
}

export default ClientsSearchAndFilter;
