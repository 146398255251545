import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createRoot } from 'react-dom/client';
// import './custom-styles.sass';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Auth0Provider } from '@auth0/auth0-react';
import { store } from './Store';
import { rootRoute } from './configs/routes/root.route';
import { ReactLocation, Router } from '@tanstack/react-location';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const reactLocation = new ReactLocation();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const persistor = persistStore(store);
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <Router location={reactLocation} routes={rootRoute}>
      <Auth0Provider
        domain={window._env_.REACT_APP_AUTH0_DOMAIN}
        clientId={window._env_.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        audience={window._env_.REACT_APP_WEF_API_URL}
        scope={window._env_.REACT_APP_AUTH0_SCOPE}
        cacheLocation="localstorage">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </Auth0Provider>
    </Router>
    <ReactQueryDevtools />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
