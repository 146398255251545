import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { clearResultsSlice } from '../ReduxSlices/resultsSlice';
import { useAppDispatch, useAppSelector } from '../hooks';

function ResultsModal() {
  const resultsState = useAppSelector((state) => state.results);
  const dispatch = useAppDispatch();

  return (
    <Modal show={!!resultsState.process}>
      <Modal.Header>Results from {resultsState.process}:</Modal.Header>
      <Modal.Body style={{ backgroundColor: resultsState.backgroundColor }}>
        {typeof resultsState.results === 'string'
          ? resultsState.results
          : Object.keys(resultsState.results).map((i) => {
              const key = i.replace(/([A-Z])/g, ' $1');
              const title = key.charAt(0).toUpperCase() + key.slice(1);
              return (
                <div key={i + Date.now()}>
                  {title}:{' '}
                  {typeof resultsState.results[i] !== 'object'
                    ? resultsState.results[i]
                    : resultsState.results[i].length}
                </div>
              );
            })}
      </Modal.Body>
      <Modal.Footer>
        <Button autoFocus variant="secondary" onClick={() => dispatch(clearResultsSlice())}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ResultsModal;
