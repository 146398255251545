import * as yup from 'yup';

export const invoiceSchema = yup.object({
  id: yup.string().uuid().required('Id is required').nullable(),
  chargeAttempts: yup.array().of(
    yup.object({
      success: yup.boolean().required('Success is required')
    })
  ),
  zeroedOutAt: yup.string()
});

export type InvoiceType = yup.InferType<typeof invoiceSchema>;

export const invoicesSchema = yup.array().of(invoiceSchema.required());

export type InvoicesType = yup.InferType<typeof invoicesSchema>;
