import React from 'react';
import Stack from 'react-bootstrap/Stack';
import ChargeAll from '../Components/Buttons/ChargeAll';
import ChargeDismissedButton from '../Components/Buttons/ChargeDismissedButton';
import StageInvoicesButton from '../Components/Buttons/StageInvoicesButton';
import GetUnpaidTicketsButton from '../Components/Buttons/GetUnpaidTicketsButton';
import { withAuthenticationRequired } from '@auth0/auth0-react';

function PaymentPage() {
  return (
    <Stack gap={5} className="mt-5">
      {[
        <StageInvoicesButton key={1} />,
        <ChargeAll key={2} />,
        <ChargeDismissedButton key={3} />,
        <GetUnpaidTicketsButton key={4} />
      ].map((Component, index) => (
        <Stack key={'payment-page-' + index} direction="horizontal">
          <h3 className="me-4">{++index}.</h3>
          {Component}
        </Stack>
      ))}
    </Stack>
  );
}

export default withAuthenticationRequired(PaymentPage);
