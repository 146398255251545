import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client, ClientTicket, FreshBookImportClient, Plate } from '../Types';
import { PlateType } from '../schemas/plate-schema';

interface ClientReduxState {
  allClientsList: Client[];
  selectedClientId: string;
  numberOfPages: number;
  currentPage: number;
  searchTerm: string;
}

const initialState: ClientReduxState = {
  allClientsList: [],
  selectedClientId: '',
  numberOfPages: undefined,
  currentPage: undefined,
  searchTerm: ''
};

export const clientsSLice = createSlice({
  name: 'clients',
  initialState: { ...initialState },
  reducers: {
    setAllClientsList: (state, action: PayloadAction<Client[]>) => {
      state.allClientsList = action.payload.map((client) => {
        const existingClient = state.allClientsList.find((c) => c.id === client.id);
        return {
          ...existingClient,
          ...client
        };
      });
    },
    setPagesInfo: (state, action: PayloadAction<{ pages: number; currentPage: number }>) => {
      state.numberOfPages = action.payload.pages;
      state.currentPage = action.payload.currentPage;
    },
    setClientSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setSelectedClientId: (state, action: PayloadAction<string>) => {
      state.selectedClientId = action.payload;
    },
    addNewClient: (state, action: PayloadAction<Client>) => {
      state.allClientsList = [...state.allClientsList, action.payload];
    },
    updateClientInRedux: (state, action: PayloadAction<Client>) => {
      state.allClientsList = state.allClientsList.map((client) =>
        client.id === action.payload.id ? action.payload : client
      );
      state.selectedClientId = action.payload.id;
    },
    synchronizeFreshBookClientInRedux: (state, action: PayloadAction<FreshBookImportClient>) => {
      state.allClientsList = state.allClientsList.map((client) =>
        client.id === action.payload.id ? { ...client, ...action.payload } : client
      );
    },
    addPlateToClient: (state, action: PayloadAction<PlateType>) => {
      state.allClientsList = state.allClientsList.map((client) =>
        client.id !== action.payload.clientId
          ? client
          : { ...client, plates: [...client.plates, action.payload] }
      );
    },
    removePlateFromClient: (state, action: PayloadAction<Plate>) => {
      state.allClientsList = state.allClientsList.map((client) =>
        client.id !== action.payload.clientId
          ? client
          : {
              ...client,
              plates: [...client.plates.filter((plate) => plate.id !== action.payload.id)]
            }
      );
    },
    updatePlateFromClient: (state, action: PayloadAction<PlateType>) => {
      state.allClientsList = state.allClientsList.map((client) =>
        client.id !== action.payload.clientId
          ? client
          : {
              ...client,
              plates: client.plates.map((plate) =>
                plate.id !== action.payload.id ? plate : action.payload
              )
            }
      );
    },
    // updateTicketFromClient: (state, action: PayloadAction<InternalTicket>) => {
    //   state.allClientsList = state.allClientsList.map((client) =>
    //     client.id !== state.selectedClientId
    //       ? client
    //       : {
    //           ...client,
    //           tickets: [
    //             ...client.tickets.map((ticket) =>
    //               ticket.internalTicket.id !== action.payload.id
    //                 ? ticket
    //                 : { ...ticket, internalTicket: action.payload }
    //             )
    //           ]
    //         }
    //   );
    // },
    updateTicketFromClient: (state, action: PayloadAction<ClientTicket>) => {
      state.allClientsList = state.allClientsList.map((client) =>
        client.id !== state.selectedClientId
          ? client
          : {
              ...client,
              tickets: [
                ...client.tickets.map((ticket) =>
                  ticket.id !== action.payload.id ? ticket : { ...ticket, ...action.payload }
                )
              ]
            }
      );
    },
    clearClientsSlice: () => {
      return initialState;
    }
  }
});

export const {
  setAllClientsList,
  setPagesInfo,
  setSelectedClientId,
  setClientSearchTerm,
  addNewClient,
  updateClientInRedux,
  addPlateToClient,
  removePlateFromClient,
  updatePlateFromClient,
  updateTicketFromClient,
  synchronizeFreshBookClientInRedux,
  clearClientsSlice
} = clientsSLice.actions;

export default clientsSLice.reducer;
