import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useMatchRoute, useNavigate } from '@tanstack/react-location';
import React, { useLayoutEffect } from 'react';
import { useAppDispatch } from '../hooks';
import { setAuth0BearerToken } from '../ReduxSlices/configSlice';

interface IAuthProviderProps {
  children: React.ReactNode;
}

function AuthProvider(props: IAuthProviderProps) {
  const { children } = props;
  const matchRoutes = useMatchRoute();
  const isLoginPage = matchRoutes({ to: '/login' });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, user, isLoading, getAccessTokenSilently } = useAuth0();

  useLayoutEffect(() => {
    const getUserMetadata = async () => {
      if (!isLoading && user && user.sub) {
        try {
          const auth0Bearer = await getAccessTokenSilently({
            audience: window._env_.REACT_APP_WEF_API_URL,
            scope: window._env_.REACT_APP_AUTH0_SCOPE
          });
          dispatch(setAuth0BearerToken(auth0Bearer));
          // window.location.pathname === '/' &&
          //   navigate({
          //     to: '/home',
          //     replace: true
          //   });
        } catch (e) {
          // navigate({
          //   to: '/login',
          //   replace: true
          // });
        }
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user, user?.sub, isLoading, dispatch, navigate]);
  if (!isLoading && !isAuthenticated && !isLoginPage) return <Navigate to="/login" replace />;
  return <>{children}</>;
}

export default AuthProvider;
