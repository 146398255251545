import { useMutation } from '@tanstack/react-query';
import { unZeroOutTickets } from '../requests/invoice.request';

export const useUnZeroOutTicketsMutation = () => {
  return useMutation({
    mutationFn: unZeroOutTickets,
    onError: (error) => {},
    onSuccess: (data) => {}
  });
};
