import React from 'react';
import Button from 'react-bootstrap/Button';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { setResults } from '../ReduxSlices/resultsSlice';
import EditMonitoringListModal from '../Containers/EditMonitoringListModal';
import { useAppDispatch } from '../hooks';
import { MonitoringListType } from '../schemas/monitoring.schema';
import { customFetch } from '../helpers/customFetch';
import { RunMonitoringListResponseType } from '../schemas/ticket.schema';
import ConfirmDialog from './dialogs/ConfirmDialog';
import { FormCheck } from 'react-bootstrap';

interface IMonitoringListItemProps {
  monitoringList: MonitoringListType;
}

function MonitoringListItem({ monitoringList }: IMonitoringListItemProps) {
  const dispatch = useAppDispatch();
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [sendingEmails, setSendingEmails] = React.useState(true);

  const handleClick = () => {
    setShowConfirm(false);
    const queryUrl = new URLSearchParams();
    queryUrl.append('sendEmail', sendingEmails.toString());
    const endpoint = `/Estimate/GenerateEstimates/${monitoringList.id}?${queryUrl.toString()}`;
    customFetch<RunMonitoringListResponseType>({
      endpoint: endpoint,
      config: { method: 'POST' },
      callback: (result) => {
        dispatch(
          setResults({
            process: 'running monitoring list - ' + monitoringList.name,
            results: `Running monitoring list completed and found ${result.data.length} tickets`
          })
        );
      }
    });
  };

  return (
    <>
      <ListGroupItem>
        <Row className="align-items-center">
          <Col xs={6} md={4}>
            <p className="h4 my-0">{monitoringList.name}</p>
            <p className="text-muted my-0">{monitoringList.description}</p>
          </Col>
          <Col xs={6} md={4} as="h3" className="text-end">
            ${monitoringList.amount.toFixed(2)}
          </Col>
          <Col className="d-flex justify-content-end gap-1">
            <EditMonitoringListModal initialFormValues={monitoringList} />
            <Button className="ps-5 pe-5" onClick={() => setShowConfirm(true)}>
              Run
            </Button>
          </Col>
        </Row>
      </ListGroupItem>
      <ConfirmDialog
        isOpen={showConfirm}
        label={
          <>
            Run Monitoring List - <strong>{monitoringList.name}</strong>?
          </>
        }
        body={
          <>
            Are you sure you want to run this monitoring list?
            <FormCheck
              label="Receive an email of camera voilation(s) found"
              checked={sendingEmails}
              onChange={(e) => setSendingEmails(e.currentTarget.checked)}
            />
          </>
        }
        confirmText="Run"
        confirmAction={handleClick}
        handleClose={() => setShowConfirm(false)}
      />
    </>
  );
}

export default MonitoringListItem;
