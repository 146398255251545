import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Result } from '../Types';

const initialState: Result = {
  process: '',
  results: {},
  backgroundColor: ''
};

export const resultsSlice = createSlice({
  name: 'results',
  initialState: { ...initialState },
  reducers: {
    setResults: (state, action: PayloadAction<Result>) => {
      state.process = action.payload.process;
      state.results = action.payload.results;
      state.backgroundColor = action.payload.backgroundColor ? action.payload.backgroundColor : '';
    },
    clearResultsSlice: () => {
      return initialState;
    }
  }
});

export const { setResults, clearResultsSlice } = resultsSlice.actions;

export default resultsSlice.reducer;
