import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  IDownloadEmailsArgs,
  createClient,
  deleteClient,
  downloadEligibleForTowingClients,
  downloadEmails,
  downloadIgnoredTickets,
  importFreshBooksClients
} from '../requests/client.request';
import { downloadBlob } from '../helpers/utils';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

export const useDownloadEmailsMutation = () => {
  return useMutation({
    mutationFn: async (args: IDownloadEmailsArgs) => await downloadEmails(args),
    onError: (error) => {},
    onSuccess: (data) => {
      downloadBlob(data, `Client Emails ${new Date().toLocaleString()}`);
    }
  });
};

export const useDownloadIgnoreTicketsMutation = () => {
  return useMutation({
    mutationFn: async () => await downloadIgnoredTickets(),
    onError: (error) => {},
    onSuccess: (data) => {
      downloadBlob(data, `Client Ignored Tickets ${new Date().toLocaleString()}.csv`);
    }
  });
};

export const useImportFreshBooksClientsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: importFreshBooksClients,
    onError: (error) => {},
    onSuccess: (data) => {
      queryClient.invalidateQueries(['clients']);
    }
  });
};

export const useCreateClientMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createClient,
    onError: (error) => {},
    onSuccess: (data) => {
      queryClient.invalidateQueries(['clients']);
    }
  });
};

export const useDeleteClientMutation = () => {
  return useMutation({
    mutationFn: deleteClient,
    onError: (error: AxiosError) => {
      toast.error(error.response?.data.toLocaleString());
    },
    onSuccess: (data) => {
      toast.success(data);
    }
  });
};

export const useDownloadEligibleForTowingClientsMutation = () => {
  return useMutation({
    mutationFn: async () => await downloadEligibleForTowingClients(),
    onError: (error) => {},
    onSuccess: (data) => {
      downloadBlob(data, `Eligible For Towing Clients ${new Date().toLocaleString()}.csv`);
    }
  });
};
