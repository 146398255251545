import { WEFResponse, PaginatedJobs } from '../Types';
import api from '../configs/api.config';

export const getJobs = async (jobType?: number, pageNumber = 1) => {
  let queryParam = '';
  if (jobType !== null) {
    queryParam = `?jobType=${jobType}&page=${pageNumber}`;
  } else {
    queryParam = `?page=${pageNumber}`;
  }
  const { data } = await api.get<WEFResponse<PaginatedJobs>>(`/Job${queryParam}`);
  return data;
};
