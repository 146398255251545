import React from 'react';
import FormControl from 'react-bootstrap/FormControl';

interface Props {
  value: any;
  name?: string;
  onChange: (_: any) => void;
}
function CustomDatePicker({ value, name, onChange }: Props) {
  return <FormControl type="date" name={name} value={value} onChange={onChange} />;
}

export default CustomDatePicker;
