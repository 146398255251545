import React from 'react';
import { Client } from '../Types';
import { useNavigate } from '@tanstack/react-location';

function ClientSummary({ client }: { client: Client }) {
  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate({ to: `/clients/${client.id}` });
  };

  return (
    <>
      <tr onClick={handleRowClick} style={{ cursor: 'pointer' }}>
        <td>{client.name}</td>
        <td>{client.emailAddress}</td>
        <td>{client.freshbooksId}</td>
        <td>{client.eligibleForTowing ? 'Yes' : 'No'}</td>
      </tr>
    </>
  );
}

export default ClientSummary;
