import React from 'react';
import Stack from 'react-bootstrap/Stack';
import GenerateInvoicesButton from '../Components/Buttons/GenerateInvoicesButton';
import StageTicketsButton from '../Components/Buttons/StageTicketsButton';
import ZeroOutInvoicesButton from '../Components/Buttons/ZeroOutInvoicesButton';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import StageTicketsFromDatabaseButton from '../Components/Buttons/StageTicketsFromDatabaseButton';

function InvoicingPage() {
  return (
    <Stack gap={5} className="mt-5">
      {[
        <StageTicketsButton key={1} />,
        <GenerateInvoicesButton key={2} />,
        <ZeroOutInvoicesButton key={3} />,
        <StageTicketsFromDatabaseButton key={4} />
      ].map((Component, index) => {
        return (
          <Stack key={'invoice-page-' + index} direction="horizontal">
            <h3 className="me-4">{++index}.</h3>
            {Component}
          </Stack>
        );
      })}
    </Stack>
  );
}

export default withAuthenticationRequired(InvoicingPage);
