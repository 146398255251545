import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useAuthCheckQuery } from '../queries/auth.query';
import { useAuthorizedUrlMutation } from '../mutations/auth.mutation';
import { useLocation } from '@tanstack/react-location';

export default function FreshBooksAuthorization() {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();
  const { data: freshBooksAuth, isFetched } = useAuthCheckQuery(
    isAuthenticated && !['/authorized'].includes(location.current.pathname)
  );
  const { mutate: authorizeFreshBooks } = useAuthorizedUrlMutation();

  useEffect(() => {
    const currentUrl = location.history.location.pathname;
    if (!isLoading && isAuthenticated && currentUrl !== '/authorized') {
      if (isFetched && !freshBooksAuth?.data) {
        void authorizeFreshBooks(null, {
          onSuccess: (data) => {
            // open new window with url
            window.open(data.data, '_blank');
          }
        });
      }
    }
  }, [
    authorizeFreshBooks,
    freshBooksAuth,
    isAuthenticated,
    isFetched,
    isLoading,
    location.history
  ]);
  return <></>;
}
