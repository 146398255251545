import React from 'react';
import Stack from 'react-bootstrap/Stack';
import AddCreditCardForm from '../Components/AddCreditCardForm';
import ClientChargeAttempts from './ClientChargeAttempts';
import { Client } from '../Types';
import ClientSavedCreditCards from './ClientSavedCreditCards';

function ClientPayments({ client }: { client: Client }) {
  const { cardPointeProfileId } = client;

  return (
    <Stack>
      <AddCreditCardForm clientId={client.id} />
      <ClientChargeAttempts client={client} />
      <ClientSavedCreditCards cardPointeProfileId={cardPointeProfileId} clientId={client.id} />
    </Stack>
  );
}

export default ClientPayments;
