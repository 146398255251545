import React, { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import Logo from '../Resources/WEF-logo.png';
import { clearAllProcesses } from '../ReduxSlices/configSlice';
import { useAuth0 } from '@auth0/auth0-react';
import { Process } from '../Types';
import { localLogOut } from '../Functions';
import { useAppDispatch, useAppSelector } from '../hooks';

function LoadingPage() {
  const processesArray = useAppSelector((state) => state.config.processesArray);
  const dispatch = useAppDispatch();
  const { logout } = useAuth0();

  const [ctrlHeld, setCtrlHeld] = useState(false);

  function downHandler({ key }) {
    if (key === 'Control') {
      setCtrlHeld(true);
    }
  }

  function upHandler({ key }) {
    if (key === 'Control') {
      setCtrlHeld(false);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return (
    <div className="text-center">
      <Image src={Logo} />
      <h2>
        <Spinner />
      </h2>
      {processesArray.map((p: Process, index: number) => (
        <h6 key={'process-' + index + '-' + Date.now()}>{p.description}</h6>
      ))}

      <small>
        Something wrong?{' '}
        <a
          href="#logout"
          className="underline"
          onClick={() => {
            if (ctrlHeld) {
              dispatch(clearAllProcesses());
            } else {
              localLogOut();
              logout({ returnTo: window.location.origin });
            }
          }}>
          {ctrlHeld ? 'Force Override Ready' : 'Click here'}
        </a>{' '}
        to log out.
      </small>
    </div>
  );
}

export default LoadingPage;
