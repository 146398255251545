import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChangeLogsMetadata } from '../Types';

interface ChangeLogsReduxState {
  changeLogsMetadata: ChangeLogsMetadata;
}

const initialState: ChangeLogsReduxState = {
  changeLogsMetadata: { showChangeLogs: false }
};

export const changeLogsSlice = createSlice({
  name: 'changeLogs',
  initialState: { ...initialState },
  reducers: {
    setChangeLogs: (state, action: PayloadAction<ChangeLogsMetadata>) => {
      state.changeLogsMetadata = action.payload;
    },
    clearChangeLogsSlice: () => {
      return initialState;
    }
  }
});

export const { setChangeLogs, clearChangeLogsSlice } = changeLogsSlice.actions;

export default changeLogsSlice.reducer;
