import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormCheck from 'react-bootstrap/FormCheck';
import Stack from 'react-bootstrap/Stack';
import { WEFResponse } from '../../Types';
import { customAxiosFetch } from '../../helpers/customFetch';

function ChargeAll() {
  const [sendingEmails, setSendingEmails] = useState(false);

  const handleChargeAll = async () => {
    await customAxiosFetch<WEFResponse<string>>({
      endpoint: '/Payment?&send-delinquent-email=' + sendingEmails,
      config: { method: 'POST' },
      processDescription: 'charging all invoices'
    });
  };

  function toggleSendingEmails(e) {
    e.stopPropagation();
    setSendingEmails((x) => !x);
  }

  return (
    <Button style={{ width: '100%' }} onClick={handleChargeAll}>
      <Row>
        <Col></Col>
        <Col>Charge All</Col>
        <Col>
          <Stack
            direction="horizontal"
            className="float-end border border-dark rounded-3 ps-2 pe-2"
            gap={2}
            onClick={toggleSendingEmails}>
            <small>{!sendingEmails && 'Not '}Sending Emails</small>
            <FormCheck checked={sendingEmails} onClick={toggleSendingEmails} />
          </Stack>
        </Col>
      </Row>
    </Button>
  );
}

export default ChargeAll;
