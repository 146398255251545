import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientTicketTabKeys } from '../Types';
interface TicketsTabReduxState {
  clientTicketTabKey: ClientTicketTabKeys;
}
const initialState: TicketsTabReduxState = {
  clientTicketTabKey: 'estimates'
};

export const ticketsTabSlice = createSlice({
  name: 'ticketsTab',
  initialState: { ...initialState },
  reducers: {
    setTicketTab: (state, action: PayloadAction<ClientTicketTabKeys>) => {
      state.clientTicketTabKey = action.payload;
    },
    clearTicketTabSlice: () => {
      return initialState;
    }
  }
});

export const { setTicketTab, clearTicketTabSlice } = ticketsTabSlice.actions;

export default ticketsTabSlice.reducer;
