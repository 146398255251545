import React from 'react';
import Button from 'react-bootstrap/Button';

function ShowClientInFreshBooks({ freshBooksId }: { freshBooksId: string }) {
  return (
    <Button
      variant="primary"
      onClick={() => window.open(`https://my.freshbooks.com/#/client/${freshBooksId}`)}>
      Show me this client in FreshBooks
    </Button>
  );
}

export default ShowClientInFreshBooks;
