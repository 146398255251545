import React, { useState } from 'react';
import ClientForm from './ClientForm';
import { Client } from '../Types';
import { useMonitoringListQuery } from '../queries/monitoring.query';
import { customAxiosFetch, customFetch } from '../helpers/customFetch';
import { updateClientInRedux } from '../ReduxSlices/clientsSlice';
import { fetchTicketsByPlates } from '../Functions';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';

type Props = {
  client: Client;
  toggleEditClientModal: () => void;
  show: boolean;
};
export default function EditClient({ client, show, toggleEditClientModal }: Props) {
  const [formData, setFormData] = useState(client);
  const dispatch = useDispatch();
  const { data } = useMonitoringListQuery({ enabled: true });
  const { unassignedMonitoringItem } = data ?? {};
  const handleDataChange = (key: keyof Client, value: unknown) =>
    setFormData({ ...formData, [key]: value });

  const updateClientInDatabase = (modifiedClient: Client) => {
    customAxiosFetch({
      endpoint: '/Client',
      callback: (r: Client) => dispatch(updateClientInRedux({ ...client, ...r })),
      config: {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          ...modifiedClient,
          monitoringListId: formData.monitoringListId
            ? formData.monitoringListId
            : unassignedMonitoringItem.id
        })
      }
    });
  };

  const submitClientForm = (e) => {
    e && e.preventDefault();
    const shouldSearchForTickets =
      client.monitoringListId !== formData.monitoringListId &&
      client.monitoringListId === unassignedMonitoringItem.id &&
      !!client.plates[0];
    updateClientInDatabase(formData);

    toggleEditClientModal();

    shouldSearchForTickets &&
      fetchTicketsByPlates(
        client.plates
          .filter((plate) => plate.archivedAt === null)
          .map((plate) => plate.plateNumber),
        { clientId: client.id }
      );
  };

  return (
    <Modal centered show={show} onHide={toggleEditClientModal} size="xl">
      <ModalHeader className="h3">Edit</ModalHeader>
      <ModalBody>
        <ClientForm
          formData={formData}
          handleDataChange={handleDataChange}
          handleSubmit={submitClientForm}
          clearForm={toggleEditClientModal}
          unassignedMonitoringListId={unassignedMonitoringItem.id}
        />
      </ModalBody>
    </Modal>
  );
}
