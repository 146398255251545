import api from '../configs/api.config';
import {
  AccessCodeResponseType,
  AuthCheckResponseType,
  AuthorizedResponseType,
  RefreshTokenResponseType,
  RevokeTokenResponseType
} from '../schemas/auth.schema';

export const getAuthorizedUrl = async (): Promise<AuthorizedResponseType> => {
  const { data } = await api.get<AuthorizedResponseType>('/Auth/FreshBooks/authorized');
  return data;
};

export const getAccessTokenByCode = async (code: string): Promise<AccessCodeResponseType> => {
  const { data } = await api.get<AccessCodeResponseType>(
    `/Auth/FreshBooksAccessToken/code/${code}`
  );
  return data;
};

export const getRefreshToken = async (): Promise<RefreshTokenResponseType> => {
  const { data } = await api.get<RefreshTokenResponseType>(
    '/Auth/FreshBooksAccessToken/refresh-token'
  );
  return data;
};

export const revokeToken = async (): Promise<RevokeTokenResponseType> => {
  const { data } = await api.get<RevokeTokenResponseType>(`/Auth/FreshBooksAccessToken/revoke`);
  return data;
};

export const checkAuth = async (): Promise<AuthCheckResponseType> => {
  const { data } = await api.get<AuthCheckResponseType>(`/Auth/FreshBooks/auth-check`);
  return data;
};
