import React, { useCallback } from 'react';
import { Button, Modal, type ModalProps } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { clearSuccessAction } from '../../ReduxSlices/successSlice';

interface ISuccessDialogProps extends ModalProps {}

export default function SuccessDialog(props: ISuccessDialogProps) {
  const { isOpen, success } = useAppSelector((state) => state.success_slice);
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(clearSuccessAction());
  }, [dispatch]);
  return (
    <Modal
      {...props}
      show={isOpen}
      size="lg"
      onHide={handleClose}
      aria-labelledby="logout-modal-centered"
      centered>
      <Modal.Header closeButton onHide={handleClose}>
        <Modal.Title id="logout-modal-centered">{`Success!`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{success}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => handleClose()}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
