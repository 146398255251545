import React, { useState } from 'react';
import { Button, Form, Stack } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { fetchTicketsByPlates } from '../Functions';

function TicketSearchByPlate() {
  const [plateNumber, setPlateNumber] = useState('');

  const handleSubmit = (e: any) => {
    e && e.preventDefault();
    fetchTicketsByPlates([plateNumber], { callback: () => setPlateNumber('') });
  };

  return (
    <Form onSubmit={handleSubmit} style={{ marginBottom: 15 }}>
      <Form.Text style={{ whiteSpace: 'pre-wrap' }}>
        {"Search NYC's Database for New Tickets by Plate Number"}
      </Form.Text>
      <Stack direction="horizontal" gap={5}>
        <Form.Control
          name="plateNumber"
          placeholder="Plate Number"
          value={plateNumber}
          onChange={(e) => setPlateNumber(e.target.value)}
        />
        <Button type="submit">
          <Search />
        </Button>
      </Stack>
    </Form>
  );
}

export default TicketSearchByPlate;
