import React from 'react';
import ClientsList from '../Components/ClientsList';
import ClientsSearchAndFilter from '../Components/ClientsSearchAndFilter';
import NewClient from '../Components/NewClient';
import ClientPaginationControls from '../Components/ClientPaginationControls';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import LoadFreshBooksClientsButton from '../Components/Buttons/ImportFreshBooksClientsButton';
import { Stack } from 'react-bootstrap';
import { useClientsQuery } from '../queries/client.query';

function ClientsPage() {
  useClientsQuery();

  return (
    <div className="mt-5">
      <NewClient />
      <br />
      <Stack direction="horizontal" gap={2}>
        <ClientsSearchAndFilter />
        <LoadFreshBooksClientsButton />
      </Stack>
      <br />
      <ClientsList />
      <ClientPaginationControls />
    </div>
  );
}

export default withAuthenticationRequired(ClientsPage);
