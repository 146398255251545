import React from 'react';
import FormSelect from 'react-bootstrap/FormSelect';
import { useMonitoringListQuery } from '../../queries/monitoring.query';

interface IMonitoringListSelectProps {
  handleDataChange: (key: string, value: string) => void;
  value: string;
  addNew?: boolean;
}

function MonitoringListSelect(props: IMonitoringListSelectProps) {
  const { handleDataChange, value, addNew = true } = props;
  const { data: { monitoringList, unassignedMonitoringItem } = {} } = useMonitoringListQuery({
    enabled: true
  });
  return (
    <FormSelect
      onChange={(e) => handleDataChange('monitoringListId', e.target.value)}
      value={value}>
      <option value={unassignedMonitoringItem?.id}>Not Monitoring</option>
      {addNew && <option value="NEW">New Monitoring List</option>}
      {monitoringList?.map((ml) => (
        <option key={ml.id} value={ml.id}>
          {ml.name} &bull; {ml.description}
        </option>
      ))}
    </FormSelect>
  );
}

export default MonitoringListSelect;
