import React from 'react';
import { Button, Form, Spinner, Stack } from 'react-bootstrap';
import { Download } from 'react-bootstrap-icons';
import { useDownloadEligibleForTowingClientsMutation } from '../../mutations/client.mutation';

function DownloadEligibleForTowingReport() {
  const { mutate: downloadEligibleForTowingClients, isLoading } =
    useDownloadEligibleForTowingClientsMutation();

  function handleSubmit(event) {
    event.preventDefault();
    downloadEligibleForTowingClients();
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Stack direction="horizontal" className="justify-content-between" gap={5}>
        <Form.Text style={{ whiteSpace: 'pre-wrap' }}>
          Download a spreadsheet of eligible for towing clients
        </Form.Text>
        <Button disabled={isLoading} type="submit">
          {isLoading ? <Spinner size="sm" animation="border" /> : <Download />}
        </Button>
      </Stack>
    </Form>
  );
}
export default DownloadEligibleForTowingReport;
