import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MonitoringList } from '../Types';

interface MonitoringListReduxState {
  allMonitoringLists: MonitoringList[];
  unassignedMonitoringListId: string;
}

const initialState: MonitoringListReduxState = {
  allMonitoringLists: [],
  unassignedMonitoringListId: ''
};

export const monitoringListsSlice = createSlice({
  name: 'monitoringLists',
  initialState: { ...initialState },
  reducers: {
    setUnassignedMonitoringListId: (
      state,
      action: PayloadAction<MonitoringListReduxState['unassignedMonitoringListId']>
    ) => {
      state.unassignedMonitoringListId = action.payload;
    },

    setAllMonitoringLists: (state, action: PayloadAction<MonitoringList[]>) => {
      const foundUnassinged = action.payload.find((ml) => ml.name.toLowerCase() === 'unassigned');
      if (foundUnassinged) {
        state.unassignedMonitoringListId = foundUnassinged.id;
      }
      state.allMonitoringLists = action.payload.filter(
        (ml) => ml.name.toLowerCase() !== 'unassigned'
      );
    },
    addNewMonitoringList: (state, action: PayloadAction<MonitoringList>) => {
      state.allMonitoringLists.push(action.payload);
    },
    updateMonitoringList: (state, action: PayloadAction<MonitoringList>) => {
      state.allMonitoringLists = state.allMonitoringLists.map((ml) =>
        ml.id !== action.payload.id ? ml : action.payload
      );
    },
    clearMonitoringListsSlice: () => {
      return initialState;
    }
  }
});

export const {
  setAllMonitoringLists,
  addNewMonitoringList,
  updateMonitoringList,
  clearMonitoringListsSlice,
  setUnassignedMonitoringListId
} = monitoringListsSlice.actions;

export default monitoringListsSlice.reducer;
