import React from 'react';

export function useDialog() {
  const [isOpen, setIsOpen] = React.useState(false);
  return {
    isOpen,
    handleOpen: () => setIsOpen(true),
    handleClose: () => setIsOpen(false)
  };
}
