import React, { Fragment, useMemo, useState } from 'react';
import { Badge, Card, Dropdown, DropdownButton, Stack } from 'react-bootstrap';
import { fetchTicketsByPlates, updatePlateInDataBase } from '../Functions';
import { useAppDispatch, useAppSelector } from '../hooks';
import { updatePlateFromClient } from '../ReduxSlices/clientsSlice';
import { setEditingPlate } from '../ReduxSlices/platesSlice';
import { MonitoringRange, Plate } from '../Types';
import { useMonitoringListQuery } from '../queries/monitoring.query';
import { setChangeLogs } from '../ReduxSlices/changeLogsSlice';

function PlateCard({ plate }: { plate: Plate & { monitoringRange: MonitoringRange } }) {
  const dispatch = useAppDispatch();
  const editingPlate = useAppSelector((state) => state.plates.editingPlate);
  const { data } = useMonitoringListQuery({ enabled: true });
  const { unassignedMonitoringItem } = data ?? {};
  const monitoringListId = useAppSelector(
    (state) =>
      state.clients.allClientsList.find((client) => client.id === state.clients.selectedClientId)!
        .monitoringListId
  );
  const clientIsMonitored = unassignedMonitoringItem?.id !== monitoringListId;

  const cardVariant = useMemo(() => {
    if ((plate.archivedAt && plate.tickets) || (editingPlate && plate.id === editingPlate.id)) {
      return 'warning';
    } else if (plate.archivedAt) {
      return 'secondary';
    } else if (plate.tickets) {
      return 'danger';
    } else {
      return 'light';
    }
  }, [editingPlate, plate.archivedAt, plate.id, plate.tickets]);

  const plateIsCurrentlyMonitored = useMemo(
    function () {
      if (!clientIsMonitored) {
        return false;
      }
      const currentDate = new Date();
      const rangeIsActive =
        currentDate > new Date(plate?.monitoringRange?.startDate) &&
        currentDate < new Date(plate?.monitoringRange?.endDate);
      return rangeIsActive;
    },
    [clientIsMonitored, plate?.monitoringRange]
  );

  const toggleArchived = () =>
    updatePlateInDataBase(
      {
        ...plate,
        archivedAt: plate.archivedAt ? null : new Date().toISOString()
      },
      (modifiedPlate: Plate) => dispatch(updatePlateFromClient(modifiedPlate))
    );

  const showPlateChangeLogsModal = (id: string) => {
    dispatch(
      setChangeLogs({
        showChangeLogs: true,
        entityId: id,
        entityName: 'Plate'
      })
    );
  };

  return (
    <Card
      bg={cardVariant}
      style={{
        borderRadius: 5,
        margin: 12,
        width: 'auto',
        justifyContent: 'center'
      }}>
      <Card.Body>
        <Card.Text
          className="text-center me-auto"
          style={{
            fontFamily: 'FE-Schrift',
            fontSize: 25
          }}>
          {plate.plateNumber.toUpperCase()}
        </Card.Text>
        <Stack direction="horizontal">
          <Badge pill className={'bg-' + (plateIsCurrentlyMonitored ? 'success' : 'warning')}>
            <small>{plateIsCurrentlyMonitored ? '' : 'Not '}Monitored</small>
          </Badge>
          <DropdownButton
            className="ms-auto"
            variant={cardVariant}
            size="sm"
            id={plate.id + 'DropdownButton'}
            title="">
            {!plate.archivedAt && (
              <Dropdown.Item
                onClick={() =>
                  dispatch(
                    setEditingPlate(editingPlate && plate.id === editingPlate.id ? null : plate)
                  )
                }>
                {editingPlate && plate.id === editingPlate.id ? 'Cancel Edit' : 'Edit'}
              </Dropdown.Item>
            )}
            <Dropdown.Item onClick={toggleArchived}>
              {plate.archivedAt ? 'Unarchive' : 'Archive'}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                fetchTicketsByPlates([plate.plateNumber], {
                  clientId: plate.clientId
                })
              }>
              Get Tickets
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                fetchTicketsByPlates(
                  [plate.plateNumber],
                  {
                    clientId: plate.clientId
                  },
                  true
                )
              }>
              Import Tickets (Admin only)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => showPlateChangeLogsModal(plate.id)}>
              History
            </Dropdown.Item>
          </DropdownButton>
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default PlateCard;
