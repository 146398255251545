import React from 'react';
import Modal from 'react-bootstrap/Modal';
import LoadingPage from '../Pages/LoadingPage';
import { useIsFetching } from '@tanstack/react-query';
import { useAppSelector } from '../hooks';
import { Spinner } from 'react-bootstrap';
function GlobalLoading() {
  const isFetching = useIsFetching();
  const configState = useAppSelector((state) => state.config);

  return (
    <>
      <Spinner
        style={{
          position: 'absolute',
          top: '20px',
          right: '20px'
        }}
        animation="border"
        hidden={!isFetching}
      />
      <Modal className="text-center" centered show={!!configState.processesArray[0]}>
        <Modal.Header className="m-auto">Fetching data from the Server</Modal.Header>
        <Modal.Body>
          <LoadingPage />
        </Modal.Body>
        <Modal.Footer className="m-auto">Spearhead Software</Modal.Footer>
      </Modal>
    </>
  );
}

export default GlobalLoading;
