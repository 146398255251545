import React, { memo } from 'react';
import Form from 'react-bootstrap/Form';
import { FormikProps } from 'formik';
import { MonitoringListType } from '../schemas/monitoring.schema';

export const monitoringFormInitialValues = {
  id: null,
  name: '',
  description: '',
  amount: 0.0
};

type IMonitoringListFormProps = {
  form: FormikProps<MonitoringListType>;
};

const MonitoringListFormItem = ({ form }: IMonitoringListFormProps) => {
  return (
    <Form
      noValidate
      validated
      onSubmit={(e) => {
        if (e.currentTarget.checkValidity() === false) {
          form.handleSubmit(e);
        }
      }}>
      <Form.Group controlId="name-input">
        <Form.Label>Name</Form.Label>
        <Form.Control
          required
          name="name"
          value={form.values.name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          className="error"
        />
        <Form.Control.Feedback type="invalid">Name is Required</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="description-input">
        <Form.Label>Description</Form.Label>
        <Form.Control
          required
          name="description"
          value={form.values.description}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        />
        <Form.Control.Feedback type="invalid">Description is Required</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="amount-input">
        <Form.Label>Amount</Form.Label>
        <Form.Control
          required
          name="amount"
          value={form.values.amount}
          type="number"
          min={0}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        />
        <Form.Control.Feedback type="invalid">Amount is Required</Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};

export default memo(MonitoringListFormItem);
