import React, { useCallback, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import MonitoringListForm, { monitoringFormInitialValues } from './MonitoringListForm';
import { useFormik } from 'formik';
import { useCreateMonitoringListMutation } from '../mutations/monitoring.mutation';
import { monitoringListSchema } from '../schemas/monitoring.schema';

type INewMonitoringListModalProps = {};

function NewMonitoringListItemModal(props: INewMonitoringListModalProps) {
  const [show, setShow] = useState(false);
  const { mutate: createMonitoringListItem, isLoading } = useCreateMonitoringListMutation();

  const formik = useFormik({
    initialValues: monitoringFormInitialValues,
    validationSchema: monitoringListSchema,
    onSubmit: (values, actions) => {
      if (!values.name) throw new Error('Name is required');
      const newMonitoringListItem = Object.fromEntries(
        Object.entries(values).filter(([_, v]) => v != null)
      );
      createMonitoringListItem(newMonitoringListItem, {
        onSuccess(data, variables, context) {
          actions.resetForm();
          setShow(false);
        }
      });
    }
  });

  const handleCloseModal = useCallback(() => {
    formik.resetForm();
    setShow(false);
  }, [formik]);

  return (
    <>
      <Button className="d-block" onClick={() => setShow(true)}>
        Add New Monitoring List
      </Button>
      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header>Create a New Monitoring List</Modal.Header>
        <Modal.Body>
          <MonitoringListForm form={formik} />
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isLoading} variant="light" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button disabled={!formik.isValid || isLoading} onClick={() => formik.handleSubmit()}>
            <Spinner size="sm" hidden={!isLoading} animation="border" className="mx-1" />
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewMonitoringListItemModal;
