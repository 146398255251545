import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import DeleteClientFormModal from './DeleteClientFormModal';

function DeleteClient() {
  const [show, setShow] = useState(false);

  const handleShowModal = () => {
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  return (
    <>
      <Button variant="danger" onClick={handleShowModal}>
        Delete Client
      </Button>
      {show && <DeleteClientFormModal show={show} onCloseModal={handleCloseModal} />}
    </>
  );
}
export default DeleteClient;
