import { useQuery } from '@tanstack/react-query';
import { getMonitoringList } from '../requests/monitoring.request';
import { useCallback } from 'react';
import { monitoringListsSchema } from '../schemas/monitoring.schema';
import toast from 'react-hot-toast';

interface IMonitoringListQuery {
  enabled?: boolean;
}

export const useMonitoringListQuery = ({ enabled = true }: IMonitoringListQuery) => {
  return useQuery({
    queryKey: ['monitoring-list'], // unique key for this query
    queryFn: () => getMonitoringList(), // function that returns a promise of the data to be fetched
    enabled, // if false, query will not execute
    retry: 2, // number of retries before failing the request
    staleTime: 1000 * 60 * 60 * 1, // 1 hours
    cacheTime: 1000 * 60 * 60 * 1, // 1 hours
    select: useCallback((data: unknown) => {
      // optional selector function that selects the data to be returned from the query
      // validate the data before returning it

      const validatedData = monitoringListsSchema.validateSync(data, {
        abortEarly: true, // return all errors
        strict: true, // do not allow extraneous fields,
        stripUnknown: true // remove extraneous fields
      });

      return {
        monitoringList: validatedData.filter((ml) => ml.name.toLowerCase() !== 'unassigned'),
        unassignedMonitoringItem: validatedData.find((ml) => ml.name.toLowerCase() === 'unassigned')
      };
    }, []),
    // useErrorBoundary: (error) => {
    //   dispatch(setErrorAction(JSON.stringify(error, null, 2)));
    //   return false;
    // },
    onError: (error) => {
      toast.error("Couldn't fetch monitoring list", { id: 'monitoring-list-error' });
      // dispatch(setErrorAction(JSON.stringify(error, null, 2)));
    },
    onSuccess: (data) => {
      toast.success('Monitoring List fetched successfully', { id: 'monitoring-list-success' });
    }
  });
};
