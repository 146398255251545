import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { ArrowUp } from 'react-bootstrap-icons';

type Props = { reason?: string };

const CannotBeFoughtTableBanner = ({ reason }: Props) => {
  return (
    <tr style={{ backgroundColor: 'red', color: 'white' }}>
      <td colSpan={10} className="text-center">
        <small>
          <ArrowUp />
          <strong>This ticket cannot be fought and can only be paid to NYC</strong>
          <ArrowUp />
          {reason && (
            <Badge className="bg-danger" pill>
              {reason}
            </Badge>
          )}
        </small>
      </td>
    </tr>
  );
};

export default CannotBeFoughtTableBanner;
