import React, { memo, useCallback, useState } from 'react';
import ConfirmDialog from './dialogs/ConfirmDialog';
import { customFetch } from '../helpers/customFetch';
import { useAppSelector } from '../hooks';
import toast from 'react-hot-toast';

export const deleteClientFormInitialValues = {
  deleteFromFreshbooks: false
};

interface Props {
  show: boolean;
  onCloseModal: () => void;
  onSuccess: () => void;
}

function SendEmailReportModal({ show, onCloseModal, onSuccess }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const client = useAppSelector((state) => state.clients);
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (!client.selectedClientId) throw new Error('Client Id is required to delete');
      await customFetch<Boolean>({
        processDescription: 'Sending Report to Client Email',
        endpoint: `/Client/${client.selectedClientId}/send-report`,
        config: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        },
        callback: (res) => {
          if (!res) return toast.error('Failed to send report to client email');
          onSuccess();
          onCloseModal();
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  return (
    <ConfirmDialog
      isOpen={show}
      label={<>Send Report Confirmation</>}
      body={<>Are you sure to send a Report to this Client?</>}
      confirmText={isLoading ? 'Sending...' : 'Send'}
      confirmAction={handleSubmit}
      handleClose={handleCloseModal}
      isLoading={isLoading}
      confirmButtonProps={{
        variant: 'info'
      }}
    />
  );
}

export default memo(SendEmailReportModal);
