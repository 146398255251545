import * as yup from 'yup';
import { createApiResponseSchema } from '../helpers/factory/api-response-schema';
import { invoiceSchema } from './invoice.schema';
import { apiResponseSchema } from './api-response.schema';

export const ticketSchema = yup.object({
  plate: yup.string().required('Plate is required'),
  state: yup.string().required('State is required'),
  licenseType: yup.string().required('License type is required'),
  summonsNumber: yup.string().required('Summons number is required'),
  isCameraViolation: yup.boolean().required('Is camera violation is required'),
  issueDate: yup.string().required('Issue date is required'),
  violationTime: yup.string().required('Violation time is required'),
  violation: yup.string().required('Violation is required'),
  fineAmount: yup.number().required('Fine amount is required'),
  penaltyAmount: yup.string().required('Penalty amount is required'),
  interestAmount: yup.string().required('Interest amount is required'),
  reductionAmount: yup.string().required('Reduction amount is required'),
  paymentAmount: yup.string().required('Payment amount is required'),
  amountDue: yup.number().required('Amount due is required'),
  precinct: yup.string().required('Precinct is required'),
  county: yup.string().required('County is required'),
  issuingAgency: yup.string().required('Issuing agency is required'),
  violationStatus: yup.string().required('Violation status is required'),
  isDismissed: yup.boolean().required('Is dismissed is required'),
  summonsImage: yup.object({
    url: yup.string().required('Image url is required'),
    description: yup.string().required('Image description is required')
  }),
  clientEmailAddress: yup.string().email().nullable()
});

export const ticketsSchema = yup.array().of(ticketSchema.required());
export const runMonitoringListResponseSchema =
  createApiResponseSchema<yup.InferType<typeof ticketsSchema>>(ticketsSchema);
export const printTicketsRequestSchema = yup.array().of(yup.string()).required();
export type RunMonitoringListResponseType = yup.InferType<typeof runMonitoringListResponseSchema>;

export const printTicketsResponseSchema = yup.object({
  blob: yup.mixed().required()
});

export type TicketType = yup.InferType<typeof ticketSchema>;

export type TicketsType = yup.InferType<typeof ticketsSchema>;

export type PrintTicketsRequestType = yup.InferType<typeof printTicketsRequestSchema>;

export type PrintTicketsResponseType = yup.InferType<typeof printTicketsResponseSchema>;

export const internalTicketSchema = yup.object({
  id: yup.string().uuid().required('Id is required').nullable(),
  plateId: yup.string().required('Plate id is required'),
  invoiceId: yup.string(),
  invoice: yup.lazy(() => invoiceSchema),
  summonsNumber: yup.string().required('Summons number is required'),
  downloadURL: yup.string(),
  settled: yup.boolean().required('Settled is required'),
  ignoreTicket: yup.boolean(),
  paidToNycAt: yup.string(),
  insertedAt: yup.string().required('Inserted at is required')
});

export const bulkIgnoreTicketsSchema = yup.object().shape({
  csvFile: yup.mixed().required('Please upload a CSV file')
});

export type BulkIgnoreTicketsSchema = yup.InferType<typeof bulkIgnoreTicketsSchema>;

const TicketSearchSchema = yup.object({
  id: yup.string().uuid('Id must be a valid uuid').required('Id is required'),
  summonsNumber: yup.string().required('SummonsNumber is required'),
  isCameraTicket: yup.boolean().nullable(),
  downloadURL: yup.string().nullable(),
  printedAt: yup.string().nullable(),
  paidToNycAt: yup.string().nullable(),
  settled: yup.string().nullable(),
  invoiceId: yup.string().nullable(),
  estimateId: yup.string().nullable(),
  ignoreTicket: yup.boolean().nullable(),
  lastSyncedWithNYC: yup.string().nullable(),
  insertedBy: yup.string().nullable(),
  insertedAt: yup.string().nullable(),
  updatedAt: yup.string().nullable(),
  plateNumber: yup.string().nullable(),
  clientName: yup.string().nullable(),
  clientId: yup.string().uuid('Id must be a valid uuid').required('Id is required')
});

const TicketSearchResponseSchema = yup.object({
  tickets: yup.array(TicketSearchSchema.nullable()),
  pages: yup.number(),
  currentPage: yup.number()
});

const TicketSearchApiResponseSchema = apiResponseSchema.shape({
  data: yup.object({
    tickets: yup.array(TicketSearchSchema.nullable()),
    pages: yup.number(),
    currentPage: yup.number()
  })
});

export type TicketSearchResponseType = yup.InferType<typeof TicketSearchResponseSchema>;
export type TicketSearchApiResponseType = yup.InferType<typeof TicketSearchApiResponseSchema>;
export type TicketSearchType = yup.InferType<typeof TicketSearchSchema>;
