import React from 'react';
import { Pagination } from 'react-bootstrap';
interface IProps {
  getJobsFromAPI: (jobType, pageNumber) => void;
  currentPage: number;
  numberOfPages: number;
  jobType?: number;
}

export const JobsPaginationControls = ({
  getJobsFromAPI,
  currentPage,
  numberOfPages,
  jobType
}: IProps) => {
  return (
    <Pagination className="justify-content-center">
      <Pagination.First disabled={currentPage === 1} onClick={() => getJobsFromAPI(jobType, 1)} />
      <Pagination.Prev
        disabled={currentPage === 1}
        onClick={() => getJobsFromAPI(jobType, currentPage - 1)}
      />
      <Pagination.Next
        disabled={currentPage === numberOfPages}
        onClick={() => {
          getJobsFromAPI(jobType, currentPage + 1);
        }}
      />
      <Pagination.Last
        disabled={currentPage === numberOfPages}
        onClick={() => getJobsFromAPI(jobType, numberOfPages)}
      />
    </Pagination>
  );
};
