import React from 'react';
import Container from 'react-bootstrap/Container';

function GlobalFooter() {
  return (
    <Container className="position-fixed fixed-bottom text-center mb-5">
      Copyright {new Date().getFullYear()}
    </Container>
  );
}

export default GlobalFooter;
