import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import MonitoringList from '../Components/MonitoringList';
import NewMonitoringListModal from '../Components/NewMonitoringListModal';
import { useMonitoringListQuery } from '../queries/monitoring.query';
import { Card, Placeholder } from 'react-bootstrap';

function MonitoringLists() {
  const { data: monitoring, isLoading } = useMonitoringListQuery({
    enabled: true
  });

  return (
    <Container>
      <div className="my-5 d-flex justify-content-center align-items-center flex-column">
        <h3>Monitoring Lists</h3>
        <NewMonitoringListModal />
      </div>
      {isLoading ? (
        <ListGroup>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} /> <Placeholder xs={2} />
            <Placeholder animation="glow">
              {' '}
              <Placeholder.Button variant="warning" xs={1} />{' '}
              <Placeholder.Button variant="primary" xs={2} />
            </Placeholder>
          </Placeholder>
          <br />
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} /> <Placeholder xs={2} />
            <Placeholder animation="glow">
              {' '}
              <Placeholder.Button variant="warning" xs={1} />{' '}
              <Placeholder.Button variant="primary" xs={2} />
            </Placeholder>
          </Placeholder>
          <br />
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} /> <Placeholder xs={2} />
            <Placeholder animation="glow">
              {' '}
              <Placeholder.Button variant="warning" xs={1} />{' '}
              <Placeholder.Button variant="primary" xs={2} />
            </Placeholder>
          </Placeholder>
        </ListGroup>
      ) : (
        <ListGroup>
          {monitoring?.monitoringList?.map((monitoringList) => (
            <MonitoringList key={monitoringList.id} monitoringList={monitoringList} />
          ))}
        </ListGroup>
      )}
    </Container>
  );
}

export default MonitoringLists;
