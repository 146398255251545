import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Process } from '../Types';

interface ConfigReduxState {
  auth0BearerToken: string;
  processesArray: Process[];
}

const initialState: ConfigReduxState = {
  auth0BearerToken: '',
  processesArray: []
};

export const configSlice = createSlice({
  name: 'config',
  initialState: { ...initialState },
  reducers: {
    setAuth0BearerToken: (state, action: PayloadAction<string>) => {
      state.auth0BearerToken = action.payload;
    },
    addProcess: (state, action: PayloadAction<Process>) => {
      state.processesArray.push(action.payload);
    },
    deleteProcess: (state, action: PayloadAction<number>) => {
      state.processesArray = state.processesArray.filter(
        (process) => process.id !== action.payload
      );
    },
    clearAllProcesses: (state) => {
      state.processesArray = [];
    },
    clearConfigSlice: () => {
      return initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setAuth0BearerToken,
  addProcess,
  deleteProcess,
  clearAllProcesses,
  clearConfigSlice
} = configSlice.actions;

export default configSlice.reducer;
