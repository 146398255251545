import React, { useCallback, useState } from 'react';
import { Modal, Button, Spinner, Stack, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import AddBulkIgnoreTicketsForm, {
  addBulkIgnoreTicketsFormInitialValue
} from './AddBulkIgnoreTicketsForm';
import { bulkIgnoreTicketsSchema } from '../schemas/ticket.schema';
import { useUploadBulkIgnoreTicketsMutation } from '../mutations/ticket.mutation';
import { downloadAsCsv } from '../Functions';

const createCSV = (data: { tickets: string }[]): string => {
  const csvHeader = 'Tickets';
  const csvRows = data.map((row) => row.tickets).join('\n');

  return `${csvHeader}\n${csvRows}`;
};

function AddBulkIgnoreTickets() {
  const [show, setShow] = useState(false);
  const { mutate: uploadBulkIgnoredTickets, isLoading } = useUploadBulkIgnoreTicketsMutation();

  const formik = useFormik({
    initialValues: addBulkIgnoreTicketsFormInitialValue,
    validationSchema: bulkIgnoreTicketsSchema,
    onSubmit: (values, actions) => {
      if (!values.csvFile) throw new Error('Csv file is required for upload');
      uploadBulkIgnoredTickets(formik.values.csvFile, {
        onSuccess() {
          actions.resetForm();
          setShow(false);
        }
      });
    }
  });

  const handleCloseModal = useCallback(() => {
    formik.resetForm();
    setShow(false);
  }, [formik]);

  const handleDownloadIgnoreTicketsSampleFile = () => {
    const csvData = [{ tickets: '123' }, { tickets: '456' }, { tickets: '789' }];

    const csvContent = createCSV(csvData);
    return downloadAsCsv(csvContent, 'Bulk Ignore Tickets Sample File');
  };

  return (
    <Stack direction="horizontal" className="justify-content-between" gap={5}>
      <Form.Text style={{ whiteSpace: 'pre-wrap' }}>Add bulk ignore tickets</Form.Text>
      <div className="text-end">
        <Button className="d-block" onClick={() => setShow(true)}>
          Add Bulk Ignore Tickets
        </Button>
      </div>
      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header>Upload Bulk Ignore Tickets</Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <Button className="text-center" onClick={handleDownloadIgnoreTicketsSampleFile}>
              Download Sample file
            </Button>
          </div>
          <AddBulkIgnoreTicketsForm form={formik} />
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isLoading} variant="light" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button disabled={!formik.isValid || isLoading} onClick={() => formik.handleSubmit()}>
            <Spinner size="sm" hidden={!isLoading} animation="border" className="mx-1" />
            {isLoading ? 'Uploading...' : 'Upload'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Stack>
  );
}

export default AddBulkIgnoreTickets;
