import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface Props {
  show: boolean;
  onCloseModal: () => void;
  onToggleIgnoreTicket: () => void;
}

function IgnoreTicketConfirmationPopup({ show, onCloseModal, onToggleIgnoreTicket }: Props) {
  return (
    <Modal show={show} onHide={onCloseModal}>
      <Modal.Header className="h4">Ignore Ticket Confirmation</Modal.Header>
      <Modal.Body>Are you sure to ignore that ticket?</Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button onClick={onToggleIgnoreTicket}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default IgnoreTicketConfirmationPopup;
