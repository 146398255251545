import { useFormik } from 'formik';
import React, { memo, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { deleteClientSchema } from '../schemas/client.schema';
import { useDeleteClientMutation } from '../mutations/client.mutation';
import { useAppDispatch, useAppSelector } from '../hooks';
import { getClients } from '../requests/client.request';
import { setAllClientsList, setPagesInfo } from '../ReduxSlices/clientsSlice';
import ConfirmDialog from './dialogs/ConfirmDialog';
import { useNavigate } from '@tanstack/react-location';

export const deleteClientFormInitialValues = {
  deleteFromFreshbooks: false
};

interface Props {
  show: boolean;
  onCloseModal: () => void;
}

function DeleteClientFormModal({ show, onCloseModal }: Props) {
  const clientState = useAppSelector((state) => state.clients);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { mutate: deleteClient, isLoading } = useDeleteClientMutation();

  const formik = useFormik({
    initialValues: deleteClientFormInitialValues,
    validationSchema: deleteClientSchema,
    onSubmit: (values, actions) => {
      if (!clientState.selectedClientId) throw new Error('Client Id is required to delete');

      const deleteClientRequest = {
        id: clientState.selectedClientId,
        params: {
          'delete-from-freshbooks': values.deleteFromFreshbooks
        }
      };

      deleteClient(deleteClientRequest, {
        onSuccess() {
          actions.resetForm();
          onCloseModal();
          navigate({ to: '/clients' });
          getClients().then((data) => {
            dispatch(setAllClientsList(data.clients));
            dispatch(setPagesInfo(data));
          });
        }
      });
    }
  });

  const handleCloseModal = useCallback(() => {
    formik.resetForm();
    onCloseModal();
  }, [formik, onCloseModal]);

  return (
    <ConfirmDialog
      isOpen={show}
      label={<>Delete Client Confirmation</>}
      body={
        <>
          Are you sure to delete that client?
          <Form>
            <Form.Check
              type="checkbox"
              id="deleteFromFreshbooks"
              label="Delete from Freshbooks too."
              name="deleteFromFreshbooks"
              onChange={formik.handleChange}
            />
          </Form>
        </>
      }
      confirmText={isLoading ? 'Deleting...' : 'Delete'}
      confirmAction={() => formik.handleSubmit()}
      handleClose={handleCloseModal}
      isLoading={isLoading}
    />
  );
}

export default memo(DeleteClientFormModal);
