import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Plate } from '../Types';

interface PlateReduxState {
  editingPlate: Plate | null;
}

const initialState: PlateReduxState = {
  editingPlate: null
};

export const platesSlice = createSlice({
  name: 'plate',
  initialState: { ...initialState },
  reducers: {
    setEditingPlate: (state, action: PayloadAction<Plate>) => {
      state.editingPlate = action.payload;
    },
    clearPlatesSlice: () => {
      return initialState;
    }
  }
});

export const { setEditingPlate, clearPlatesSlice } = platesSlice.actions;

export default platesSlice.reducer;
