import { PayloadAction, createSlice } from '@reduxjs/toolkit';
export const ERROR_SLICE_NAME = 'error_slice';

interface ErrorReduxState {
  error: string | null;
  isOpen: boolean;
}

const initialState: ErrorReduxState = {
  error: null,
  isOpen: false
};

export const errorSlice = createSlice({
  name: 'error',
  initialState: { ...initialState },
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isOpen = true;
    },
    clearError: () => {
      return initialState;
    }
  }
});

export const { setError: setErrorAction, clearError: clearErrorAction } = errorSlice.actions;
export default errorSlice.reducer;
