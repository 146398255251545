import { ClientTicket } from '../Types';
import api from '../configs/api.config';

export interface IUnZeroOutTicketsRequest {
  tickets: ClientTicket[];
  clientId: string;
}

export const unZeroOutTickets = async (payload: IUnZeroOutTicketsRequest) => {
  const { data } = await api.post('/Invoice/UnZeroOutTickets', payload);
  return data;
};
