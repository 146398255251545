import React from 'react';
import Stack from 'react-bootstrap/Stack';
import { Client } from '../Types';

interface IClientsChargeAttemptsProps {
  client: Client & {
    succesfullChargeAttempts?: number;
    failedChargeAttempts?: number;
  };
}

function ClientChargeAttempts({ client }: IClientsChargeAttemptsProps) {
  return (
    <Stack>
      <h2>Charge Attempts Summary</h2>
      <Stack direction="horizontal" gap={5}>
        <>
          <strong>Succeeded: </strong>
          <big>{client.succesfullChargeAttempts}</big>
        </>
        <>
          <strong>Failed: </strong>
          <big>{client.failedChargeAttempts}</big>
        </>
      </Stack>
    </Stack>
  );
}

export default ClientChargeAttempts;
