import React from 'react';

function WelcomeText() {
  return (
    <div>
      <h2>Welcome!</h2>
      <strong>Click the button below to give us access to your account!</strong>
    </div>
  );
}

export default WelcomeText;
