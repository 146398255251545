import React, { useState } from 'react';
import { Stack, FormCheck, Button } from 'react-bootstrap';
import NewPlateCollapse from '../Components/NewPlateCollapse';
import PlatesGroup from '../Components/PlatesGroup';
import { fetchTicketsByPlates } from '../Functions';
import { DeepClient, MonitoringList } from '../Types';
import { useMonitoringListQuery } from '../queries/monitoring.query';

interface IClientPlatesProps {
  client: DeepClient;
}
function ClientPlates({ client }: IClientPlatesProps) {
  const [showArchived, setShowArchived] = useState(false);
  const [selectingMultiple, setSelectingMultiple] = useState(false);
  const [selectedPlates, setSelectedPlates] = useState([]);
  const { data } = useMonitoringListQuery({
    enabled: client.monitoringListId !== null
  });

  const clientMonitoringList = data?.monitoringList.find(
    (m) => m.id === client.monitoringListId
  ) as MonitoringList;

  const togglePlateSelect = (plateNumber: string) => {
    setSelectedPlates(
      selectedPlates.includes(plateNumber)
        ? selectedPlates.filter((x) => x !== plateNumber)
        : [...selectedPlates, plateNumber]
    );
  };

  const submitSelectedPlates = () =>
    fetchTicketsByPlates(selectedPlates, {
      callback: () => {
        setSelectedPlates([]);
        setSelectingMultiple(false);
      },
      clientId: client.id
    });

  return (
    <Stack>
      <NewPlateCollapse
        delinquent={client.delinquent}
        client={client}
        monitoringList={clientMonitoringList}
        disabled={selectingMultiple}
      />
      <Stack gap={2} direction="horizontal">
        <Button
          className="mt-1"
          variant={selectingMultiple ? 'primary' : 'info'}
          onClick={() => (selectingMultiple ? submitSelectedPlates() : setSelectingMultiple(true))}
          size="sm">
          {!selectingMultiple ? 'Select to Scan' : 'Scan Selected Plates'}
        </Button>
        {selectingMultiple ? (
          <Button
            size="sm"
            className="ms-auto mt-1"
            variant="secondary"
            onClick={() => setSelectingMultiple(false)}>
            Cancel
          </Button>
        ) : (
          <>
            <FormCheck
              type="checkbox"
              label="Show Archived"
              className="ms-auto"
              checked={showArchived}
              onChange={() => setShowArchived(!showArchived)}
            />
          </>
        )}
      </Stack>
      {client.plates && (
        <PlatesGroup
          selectedPlateNumbers={selectedPlates}
          selectingMultiple={selectingMultiple}
          togglePlateNumberSelect={togglePlateSelect}
          plates={client.plates}
          showArchived={showArchived}
        />
      )}
    </Stack>
  );
}

export default ClientPlates;
