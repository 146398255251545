import React from 'react';

interface IProps {
  title: string;
  body: string;
}

export default function ToastMessage({ title, body }: IProps) {
  return (
    <div>
      <strong>{title}</strong>
      <p>{body}</p>
    </div>
  );
}
