import React from 'react';
import GlobalButton from './GlobalButton';
import { useAppDispatch } from '../../hooks';
import { setResults } from '../../ReduxSlices/resultsSlice';
import { customFetch } from '../../helpers/customFetch';

function GetUnpaidTicketsButton() {
  const dispatch = useAppDispatch();
  const handleClick = () => {
    customFetch({
      endpoint: '/Ticket/GetUnPaidTickets',
      callback: (res: Response) => {
        dispatch(
          setResults({
            process: 'Sending unpaid ticket reports',
            results: res.ok ? 'Emails sent!' : 'Internal Server Error',
            backgroundColor: res.ok ? '' : 'orangered'
          })
        );
      },
      skipParseBody: true,
      processDescription: 'Getting unpaid tickets'
    });
  };

  return <GlobalButton onClick={handleClick} buttonText="Get Unpaid Tickets" />;
}

export default GetUnpaidTicketsButton;
