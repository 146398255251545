import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { customAxiosFetch } from '../helpers/customFetch';
import { AnalyticsData, WEFResponse } from '../Types';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const AnalyticsPage = () => {
  const [analyticsData, setAnalyticsData] = useState<AnalyticsData>(null);
  useEffect(() => {
    customAxiosFetch<WEFResponse<AnalyticsData>>({
      endpoint: '/Analytics',
      callback: (result) => {
        setAnalyticsData(result?.data);
      }
    });
  }, []);

  return (
    <div className="mt-5">
      {analyticsData ? (
        <Table striped bordered hover responsive className="text-center">
          <thead>
            <tr>
              <th>Total number of tickets waiting to be invoiced</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{analyticsData.totalTicketsWaitingForInvoicing}</td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <h2 className="text-center">No analytics available.</h2>
      )}
    </div>
  );
};

export default withAuthenticationRequired(AnalyticsPage);
