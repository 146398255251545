import * as yup from 'yup';
import { apiResponseSchema } from './api-response.schema';

export const clientSchema = yup.object({
  alternateEmailAddress: yup.string().nullable(),
  alternatePhoneNumber: yup.string().optional(),
  billingCity: yup.string().required('Billing city is required'),
  billingCountry: yup.string().required('Billing country is required'),
  billingPostCode: yup.string().required('Billing post code is required'),
  billingState: yup.string().required('Billing state is required'),
  billingStreet: yup.string().required('Billing street is required'),
  cardPointeProfileId: yup.number().nullable(),
  eligibleForTowing: yup.boolean().required(),
  emailAddress: yup.string().email('Email must be valid').required('Email is required'),
  freshbooksId: yup.string().required(),
  id: yup.string().uuid('Id must be a valid uuid').required('Id is required'),
  ignoreCameraViolations: yup.boolean().required('Ignore camera violations is required'),
  monitoringList: null,
  monitoringListId: yup.string().uuid('Monitoring list id must be a valid uuid').nullable(),
  name: yup.string().required('Name is required'),
  phoneNumber: yup.string().nullable(),
  plates: yup.lazy(() => yup.mixed().nullable()),
  recurringInvoiceFreshbooksId: yup.string().nullable()
});

export const deleteClientSchema = yup.object({
  deleteFromFreshbooks: yup.boolean()
});

export type DeleteClientSchema = yup.InferType<typeof deleteClientSchema>;

const ClientSearchDropdownSchema = yup.object({
  id: yup.string().uuid('Id must be a valid uuid').required('Id is required'),
  name: yup.string().required('Name is required')
});
const ClientSearchDropdownResponseSchema = apiResponseSchema.shape({
  data: yup.array(ClientSearchDropdownSchema.required('Data is required').nullable())
});

export type ClientSearchDropdownkResponseType = yup.InferType<
  typeof ClientSearchDropdownResponseSchema
>;

const ClientTicketSummaryReportResponseSchema = apiResponseSchema.shape({
  data: yup.string().required()
});

export type ClientTicketSummaryReportResponseType = yup.InferType<
  typeof ClientTicketSummaryReportResponseSchema
>;
