import React from 'react';
import Stack from 'react-bootstrap/Stack';
import FormCheck from 'react-bootstrap/FormCheck';

interface Props {
  value: boolean;
  handleDataChange: (action: string, value: boolean) => void;
}

function IgnoreCameraToggle({ value, handleDataChange }: Props) {
  return (
    <Stack direction="horizontal" gap={2} className="my-2">
      <FormCheck
        className="me-auto"
        label="Ignore Camera Violations"
        type="checkbox"
        checked={value}
        onChange={(e) => handleDataChange('ignoreCameraViolations', e.target.checked)}
      />
    </Stack>
  );
}

export default IgnoreCameraToggle;
