import React from 'react';
import Stack from 'react-bootstrap/Stack';
import { ArrowLeft, Envelope, Telephone, TelephoneFill } from 'react-bootstrap-icons';
import { Client } from '../Types';
import { useNavigate } from '@tanstack/react-location';
import SendEmailReport from './Buttons/SendEmailReport';

type Props = {
  client: Client;
};

const ClientInfo = ({ client }: Props) => {
  const navigate = useNavigate();
  return (
    <Stack>
      <p className="h4">
        <ArrowLeft
          style={{
            cursor: 'pointer'
          }}
          size={35}
          className="me-2"
          onClick={() => navigate({ to: `/clients` })}
        />
        {client.name}
      </p>
      {/* {client.emailAddress && (
        <p>
          <EnvelopeFill /> {client.emailAddress}
        </p>
      )} */}
      <SendEmailReport emailAddress={client.emailAddress} />
      {client.alternateEmailAddress && (
        <p>
          <Envelope />
          {client.alternateEmailAddress}
          <small> Alternate</small>
        </p>
      )}
      {client.phoneNumber && (
        <p>
          <TelephoneFill /> {client.phoneNumber}
        </p>
      )}
      {client.alternatePhoneNumber && (
        <p>
          <Telephone /> {client.alternatePhoneNumber}
          <small> Alternate</small>
        </p>
      )}
    </Stack>
  );
};

export default ClientInfo;
