import React from 'react';
import { Table } from 'react-bootstrap';
import { TicketSearchType } from '../../schemas/ticket.schema';
import { useNavigate } from '@tanstack/react-location';

interface IProps {
  tickets: TicketSearchType[];
}
export default function TicketSearchTable({ tickets }: IProps) {
  const navigate = useNavigate();
  function handleClientClick(clientId) {
    navigate({ to: `/clients/${clientId}` });
  }
  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>SummonsNumber</th>
            <th>IsCameraTicket</th>
            <th>SummonsImage</th>
            <th>PrintedAt</th>
            <th>PaidToNycAt</th>
            <th>Settled</th>
            <th>InvoiceId</th>
            <th>EstimateId</th>
            <th>IgnoreTicket</th>
            <th>LastSyncedWithNYC</th>
            <th>ImportedBy</th>
            <th>ImportedDate</th>
            <th>UpdatedAt</th>
            <th>PlateNumber</th>
            <th>Client</th>
          </tr>
        </thead>
        <tbody>
          {tickets &&
            tickets.length > 0 &&
            tickets.map((ticket) => (
              <tr key={ticket.id}>
                <td>{ticket.summonsNumber}</td>
                <td>{ticket.isCameraTicket}</td>
                <td>
                  <a href={ticket.downloadURL} target="blank">
                    View
                  </a>
                </td>
                <td>{ticket.printedAt}</td>
                <td>{ticket.paidToNycAt}</td>
                <td>{ticket.settled}</td>
                <td>{ticket.invoiceId}</td>
                <td>{ticket.estimateId}</td>
                <td>{ticket.ignoreTicket}</td>
                <td>{ticket.lastSyncedWithNYC}</td>
                <td>{ticket.insertedBy}</td>
                <td>{ticket.insertedAt}</td>
                <td>{ticket.updatedAt}</td>
                <td>{ticket.plateNumber}</td>
                <td
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => handleClientClick(ticket.clientId)}>
                  {ticket.clientName}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}
