import { useQuery } from '@tanstack/react-query';
import { IClientRequest, getClients } from '../requests/client.request';
import { setAllClientsList, setPagesInfo } from '../ReduxSlices/clientsSlice';
import { useAppDispatch } from '../hooks';
import toast from 'react-hot-toast';

export const useClientsQuery = (args?: IClientRequest) => {
  const dispatch = useAppDispatch();
  return useQuery({
    enabled: true,
    queryKey: ['clients', args],
    queryFn: async () => await getClients(args),
    onError: (error) => {
      toast.error("Couldn't fetch clients", { id: 'clients-error' });
    },
    onSuccess: (data) => {
      toast.success('Clients fetched successfully', { id: 'clients-success' });
      dispatch(setAllClientsList(data.clients));
      dispatch(setPagesInfo(data));
    }
  });
};
