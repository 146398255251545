import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { InternalTicket } from '../Types';
import { setTicketsToBePrinted } from '../ReduxSlices/ticketsSlice';
import { setResults } from '../ReduxSlices/resultsSlice';
import GlobalActionModal from '../Components/GlobalActionModal';
import { useAppDispatch, useAppSelector } from '../hooks';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { customAxiosFetch, customFetch } from '../helpers/customFetch';
import { FiTrash2 } from 'react-icons/fi';
import { Button } from 'react-bootstrap';
import ConfirmDialog from '../Components/dialogs/ConfirmDialog';

const PrintQueuePage = () => {
  const [summonsNumber, setSummonsNumber] = useState<string>();
  const [showModal, setShowModal] = useState(false);
  const ticketsToBePrinted = useAppSelector((state) => state?.tickets?.ticketsToBePrinted);
  const dispatch = useAppDispatch();

  function emailTicketsToPrint() {
    const processDescription = 'processing and emailing tickets to you';
    customFetch({
      endpoint: '/Ticket/EmailTicketsInPrintQueue',
      processDescription: processDescription,
      callback: () => {
        return;
      },
      config: {
        method: 'POST'
      },
      skipParseBody: true
    });
    // appHistory.push('home');
    dispatch(
      setResults({
        process: processDescription,
        results: 'Tickets are being processed, look out for an email from us'
      })
    );
  }

  function handleDeleteTicketInPrintQueue() {
    customAxiosFetch<{
      success: boolean;
    }>({
      endpoint: `/Ticket/delete-ticket-in-print-queue/${summonsNumber}`,
      config: { method: 'DELETE' },
      callback: (result) => {
        setShowModal(false);
        if (!result?.success) return;
        getTicketsInPrintQueue();
      }
    });
  }

  function handleOpenDeleteModal(summonsNumber: string) {
    setSummonsNumber(summonsNumber);
    setShowModal(true);
  }

  function handleCloseDeleteModal() {
    setSummonsNumber('');
    setShowModal(false);
  }

  function getTicketsInPrintQueue() {
    customFetch({
      endpoint: '/Ticket/GetTicketsInPrintQueue',
      processDescription: 'Fetching tickets',
      callback: (tickets: InternalTicket[]) => dispatch(setTicketsToBePrinted(tickets))
    });
  }

  useEffect(() => {
    getTicketsInPrintQueue();
  }, [dispatch]);
  return (
    <div className="mt-5 text-center">
      {ticketsToBePrinted && ticketsToBePrinted.length > 0 ? (
        <>
          <h3>{ticketsToBePrinted.length} Tickets are Ready to be Printed</h3>
          <div
            style={{
              overflowX: 'hidden',
              overflowY: 'scroll',
              maxHeight: window.innerHeight / 1.5
            }}>
            <Table striped bordered>
              <thead
                style={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: 'white',
                  zIndex: 2
                }}>
                <tr>
                  <th>Summons Number</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {ticketsToBePrinted.map((ticket) => {
                  return (
                    <tr key={ticket.id}>
                      <td>{ticket.summonsNumber}</td>
                      <td>
                        <Button
                          variant="danger"
                          onClick={() => handleOpenDeleteModal(ticket.summonsNumber)}>
                          <FiTrash2 className="bg-red"></FiTrash2>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <ConfirmDialog
            isOpen={showModal}
            label={<>Delete ticket in Print Queue</>}
            body={
              <>
                Are you sure you want to delete this ticket? This will remove this ticket from the
                print queue.
              </>
            }
            confirmText={'Delete'}
            confirmAction={handleDeleteTicketInPrintQueue}
            handleClose={handleCloseDeleteModal}
          />

          <GlobalActionModal
            actionText="Are you sure you want to print these tickets?"
            warningText="This will remove these tickets from the print queue"
            proceedCallback={emailTicketsToPrint}
            buttonText="Print Tickets"
          />
        </>
      ) : (
        <h3>No Tickets are ready to Print</h3>
      )}
    </div>
  );
};

export default withAuthenticationRequired(PrintQueuePage);
