import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import MonitoringListSelect from '../Components/FormComponents/MonitoringListSelect';
import { downloadAsCsv } from '../Functions';
import { Download } from 'react-bootstrap-icons';
import { useMonitoringListQuery } from '../queries/monitoring.query';
import { customFetch } from '../helpers/customFetch';

const DownloadClientPlates = () => {
  const { data: monitoringLists } = useMonitoringListQuery({ enabled: true });
  const [monitoringListId, setMonitoringListId] = useState('');
  const [onlyMonitorePlates, setOnlyMonitorePlates] = useState(true);
  const { unassignedMonitoringItem } = monitoringLists || {};
  const handleSubmit = (e) => {
    e && e.preventDefault();
    const params = new URLSearchParams();
    if (monitoringListId) params.append('monitoringListId', monitoringListId);
    if (!!monitoringListId && monitoringListId !== unassignedMonitoringItem.id) {
      params.append('onlyMonitoredPlates', onlyMonitorePlates.toString());
    }
    const requestConfig: RequestInit = {
      headers: {
        'Content-Type': 'text/csv;charset=UTF-8'
      }
    };
    const endpoint = '/Client/DownloadClientsWithPlates?' + params.toString();
    customFetch<any>({
      endpoint,
      config: requestConfig,
      skipParseBody: true,
      callback: async (res) =>
        downloadAsCsv(await res.text(), `Client Plates ${new Date().toLocaleString()}`)
    });
  };

  return (
    <Form onSubmit={handleSubmit} style={{ marginBottom: 15 }}>
      <Stack direction="horizontal" gap={5} className="">
        <Stack direction="vertical" className="w-100">
          <Form.Text style={{ whiteSpace: 'pre-wrap' }}>
            Download a spreadsheet of all clients with their plates
          </Form.Text>
          {monitoringListId && monitoringListId !== unassignedMonitoringItem.id && (
            <Form.Check
              checked={onlyMonitorePlates}
              label="Only plates that are bieng monitored"
              onChange={(e) => setOnlyMonitorePlates(e.currentTarget.checked)}
            />
          )}
        </Stack>
        {monitoringListId && (
          <MonitoringListSelect
            value={monitoringListId}
            addNew={false}
            handleDataChange={(_, value) => setMonitoringListId(value)}
          />
        )}
        <Button
          className="ms-auto"
          style={{ whiteSpace: 'nowrap' }}
          variant={monitoringListId ? 'secondary' : 'primary'}
          onClick={() => setMonitoringListId(!monitoringListId ? unassignedMonitoringItem.id : '')}>
          {monitoringListId ? 'Clear Filter' : 'Add Filter'}
        </Button>
        <Button type="submit">
          <Download />
        </Button>
      </Stack>
    </Form>
  );
};

export default DownloadClientPlates;
