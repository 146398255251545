import React from 'react';
import { setResults } from '../../ReduxSlices/resultsSlice';
import GlobalButton from './GlobalButton';
import { useAppDispatch } from '../../hooks';
import { customFetch } from '../../helpers/customFetch';

interface IChargeDismissedButtonProps {
  invoiceId?: string;
}

function ChargeDismissedButton({ invoiceId = null }: IChargeDismissedButtonProps) {
  const dispatch = useAppDispatch();

  const handleChargeDismissed = () => {
    customFetch({
      endpoint: '/Payment/chargeDismissed' + (invoiceId ? '/' + invoiceId : ''),
      config: { method: 'POST' },
      processDescription: 'charging dismissed tickets',
      callback: () =>
        dispatch(
          setResults({
            process: 'charging dismissed tickets',
            results: 'Success!'
          })
        ),
      skipParseBody: true
    });
  };

  return (
    <GlobalButton
      onClick={handleChargeDismissed}
      buttonText={
        invoiceId ? 'Charge these dismissed tickets' : 'Charge all available dismissed tickets'
      }
      // disabled={false}
      // loading={false}
      // variant=""
    />
  );
}

export default ChargeDismissedButton;
