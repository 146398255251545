import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import { useDialog } from '../../hooks/useDialog';
import { useAuth0 } from '@auth0/auth0-react';
import { localLogOut } from '../../Functions';
import { Nav } from 'react-bootstrap';
import { FiLogOut } from 'react-icons/fi';
interface ILogoutModalProps extends ModalProps {}

export default function LogoutModal(props: ILogoutModalProps) {
  const { isOpen, handleClose, handleOpen } = useDialog();
  const { logout } = useAuth0();

  const logOut = () => {
    localLogOut();
    logout({ returnTo: window.location.origin });
  };

  return (
    <>
      <Nav.Link className="text-white " eventKey={2} onClick={() => handleOpen()}>
        <FiLogOut className="mx-2" />
        Logout
      </Nav.Link>
      <Modal
        {...props}
        show={isOpen}
        size="lg"
        onHide={handleClose}
        aria-labelledby="logout-modal-cenetered"
        centered>
        <Modal.Header closeButton onHide={handleClose}>
          <Modal.Title id="logout-modal-cenetered">{`Oops! You're about to leave WeFightTickets`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to leave?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => handleClose()}>
            Close
          </Button>
          <Button onClick={() => logOut()}>Logout</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
