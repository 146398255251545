import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MonitoringListType } from '../schemas/monitoring.schema';
import { createMonitoringList, updateMonitoringList } from '../requests/monitoring.request';

export const useCreateMonitoringListMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: MonitoringListType) => createMonitoringList(data),
    onError: (error) => {},
    onSuccess: (data) => {
      queryClient.invalidateQueries(['monitoring-list']);
    }
  });
};

export const useUpdateMonitoringListMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['monitoringList', 'update'],
    mutationFn: (data: MonitoringListType) => updateMonitoringList(data),
    onError: (error) => {},
    onSuccess: (data) => {
      queryClient.invalidateQueries(['monitoring-list']);
    }
  });
};
