import React, { ReactElement } from 'react';
import { useState } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

type Props = {
  variant?: string;
  buttonText?: string;
  actionText: string;
  warningText: string;
  proceedCallback: Function;
  useTooltip?: boolean;
  child?: ReactElement;
};

/**
 * @remarks
 * This component will provide a button that will open a modal to let the user confirm or cancel the requested action
 *
 * @param variant - A valid string corresponding to a Bootstrap theme
 * @param buttonText - A string to be placed in the button
 * @param warningText - A warning to the user, like "This cannot be undone"
 * @param actionText - A complete sentance, ex. "Are you sure you want to use this component?"
 * @param proceedCallback - The function to call when the user clicks to confirm the action
 * @param useTooltip - Set to 'true' when using a passed in child
 * @param child - A component to use in place of the button
 * @returns A popup modal asking if user wants to do x action
 */
export default function GlobalActionModal({
  variant = 'primary',
  buttonText = 'Unknown action',
  actionText,
  warningText,
  proceedCallback,
  useTooltip,
  child
}: Props) {
  const [show, setShow] = useState(false);

  const toggleModal = () => setShow(!show);

  const renderTooltip = (e: any) => (
    <Tooltip {...e} id="global-action-modal-tooltip">
      {buttonText}
    </Tooltip>
  );

  const Content = child ? (
    React.cloneElement(child, { onClick: toggleModal })
  ) : (
    <Button variant={variant} onClick={toggleModal}>
      {buttonText}
    </Button>
  );

  return (
    <>
      {useTooltip ? <OverlayTrigger overlay={renderTooltip}>{Content}</OverlayTrigger> : Content}
      <Modal show={show} onHide={toggleModal}>
        <Modal.Header>
          <Modal.Title>{actionText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{warningText}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button autoFocus variant="secondary" onClick={toggleModal}>
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              proceedCallback();
              toggleModal();
            }}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
