import React from 'react';
import { Badge, Card, Stack } from 'react-bootstrap';
import { Check } from 'react-bootstrap-icons';
import { MonitoringRange, Plate } from '../Types';

interface ISelectablePlateCardProps {
  plate: Plate & { monitoringRange: MonitoringRange };
  togglePlateNumberSelect: (_: string) => void;
  selectedPlateNumbers: string[];
}

function SelectablePlateCard({
  plate,
  togglePlateNumberSelect,
  selectedPlateNumbers
}: ISelectablePlateCardProps) {
  const plateIsSelected = selectedPlateNumbers.includes(plate.plateNumber);
  return (
    <Card
      style={{
        backgroundColor: plateIsSelected ? 'lightgreen' : '',
        borderRadius: 5,
        margin: 12,
        width: 'auto'
      }}
      as="button"
      onClick={() => togglePlateNumberSelect(plate.plateNumber)}>
      <Card.Body style={{ width: '100%' }}>
        <Stack direction="horizontal" gap={2}>
          <Badge bg="light" pill>
            <Check size={25} color={plateIsSelected ? 'green' : 'white'} />
          </Badge>
          <Card.Text
            style={{
              fontFamily: 'FE-Schrift',
              fontSize: 25
            }}>
            {plate.plateNumber.toUpperCase()}
          </Card.Text>
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default SelectablePlateCard;
