import React from 'react';
import { ClientTicket } from '../../Types';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useUnZeroOutTicketsMutation } from '../../mutations/invoice.mutation';

interface IUnZeroOutTicketsButtonProps {
  tickets: ClientTicket[];
  clientId: string;
}

export default function UnZeroOutTicketsButton({
  tickets,
  clientId
}: IUnZeroOutTicketsButtonProps) {
  const { mutateAsync: unZeroOutTickets, isLoading } = useUnZeroOutTicketsMutation();

  const handleUnZeroOutTickets = () => {
    if (!tickets.length) {
      toast.error('No tickets selected');
      return;
    }
    toast.promise(unZeroOutTickets({ tickets, clientId }), {
      loading: `Un-Zero-ing Out ${tickets.length} Tickets`,
      success: 'Successfully Un-Zero-ed Out Tickets',
      error: 'Error Un-Zero-ing Out Tickets'
    });
  };
  return (
    <Button disabled={isLoading} size="sm" variant="danger" onClick={handleUnZeroOutTickets}>
      Un-Zero-Out These Tickets
    </Button>
  );
}
