import api from '../configs/api.config';
import { MonitoringListType } from '../schemas/monitoring.schema';

export const getMonitoringList = async () => {
  const response = await api.get('/MonitoringList');
  return response.data;
};

export const createMonitoringList = async (data: MonitoringListType) => {
  const response = await api.post('/MonitoringList', data);
  return response.data;
};

export const updateMonitoringList = async (data: MonitoringListType) => {
  const response = await api.put('/MonitoringList', data);
  return response.data;
};
