import * as yup from 'yup';

export const monitoringListSchema = yup.object({
  id: yup.string().uuid().required('Id is required').nullable(),
  name: yup.string().optional().nonNullable(),
  description: yup.string().required('Description is required'),
  amount: yup.number().required('Amount is required')
});

export const monitoringListsSchema = yup.array().of(monitoringListSchema);

export type MonitoringListType = yup.InferType<typeof monitoringListSchema>;
export type MonitoringListsType = yup.InferType<typeof monitoringListsSchema>;
