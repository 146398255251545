import React, { memo, useCallback, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { MonitoringListType, monitoringListSchema } from '../schemas/monitoring.schema';
import { useFormik } from 'formik';
import { useUpdateMonitoringListMutation } from '../mutations/monitoring.mutation';
import MonitoringListForm from '../Components/MonitoringListForm';
import { Spinner } from 'react-bootstrap';

type IEditMonitoringListModalProps = {
  initialFormValues: MonitoringListType;
};

const EditMonitoringListItemModal = (props: IEditMonitoringListModalProps) => {
  const { initialFormValues } = props;
  const [show, setShow] = useState(false);
  const { mutate: updateMonitoringListItem, isLoading } = useUpdateMonitoringListMutation();

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: monitoringListSchema,
    onSubmit: (values) => {
      if (!formik.isValid) throw new Error('Name is required');
      updateMonitoringListItem(formik.values, {
        onSuccess: (data, variables, context) => {
          handleClose();
        },
        onError: (error, variables, context) => {}
      });
    }
  });

  const handleClose = useCallback(() => {
    formik.resetForm();
    setShow(false);
  }, [formik]);

  return (
    <>
      <Button variant="warning" onClick={() => setShow(true)}>
        Edit
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>Update Monitoring Item</Modal.Header>
        <Modal.Body>
          <MonitoringListForm form={formik} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" disabled={isLoading} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={!formik.isValid || isLoading} onClick={() => formik.handleSubmit()}>
            <Spinner hidden={!isLoading} size="sm" animation="border" className="mx-1" />
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(EditMonitoringListItemModal);
