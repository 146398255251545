import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import { Download } from 'react-bootstrap-icons';
import MonitoringListSelect from './FormComponents/MonitoringListSelect';

import { useDownloadIgnoreTicketsMutation } from '../mutations/client.mutation';
import { Spinner } from 'react-bootstrap';
import { useMonitoringListQuery } from '../queries/monitoring.query';

function DownloadIgnoredTickets() {
  const { data: monitoringLists } = useMonitoringListQuery({ enabled: true });
  const { unassignedMonitoringItem } = monitoringLists || {};
  const { mutate: downloadIgnoredTickets, isLoading } = useDownloadIgnoreTicketsMutation();

  const [monitoringListId, setMonitoringListId] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e && e.preventDefault();
    downloadIgnoredTickets();
  };

  return (
    <Form onSubmit={handleSubmit} style={{ marginBottom: 15 }}>
      <Stack direction="horizontal" gap={5}>
        <Form.Text style={{ whiteSpace: 'pre-wrap' }}>
          Download a spreadsheet of all client ignored tickets
        </Form.Text>
        {monitoringListId && (
          <MonitoringListSelect
            value={monitoringListId}
            addNew={false}
            handleDataChange={(_, value) => setMonitoringListId(value)}
          />
        )}
        <Button
          className="ms-auto"
          style={{ whiteSpace: 'nowrap' }}
          variant={monitoringListId ? 'secondary' : 'primary'}
          onClick={() => setMonitoringListId(!monitoringListId ? unassignedMonitoringItem.id : '')}>
          {monitoringListId ? 'Clear Filter' : 'Add Filter'}
        </Button>
        <Button type="submit">
          {isLoading ? <Spinner animation="border" size="sm" /> : <Download size={20} />}
        </Button>
      </Stack>
    </Form>
  );
}

export default DownloadIgnoredTickets;
