import React from 'react';
import { setResults } from '../../ReduxSlices/resultsSlice';
import GlobalButton from './GlobalButton';
import { useAppDispatch } from '../../hooks';
import { customFetch } from '../../helpers/customFetch';

function StageTicketsFromDatabaseButton() {
  const dispatch = useAppDispatch();

  const handleStageTicketsFromDb = () => {
    customFetch({
      endpoint: '/Invoice/stage-tickets-from-db',
      config: { method: 'POST' },
      processDescription: 'Staging tickets from database',
      skipParseBody: true,
      callback: () =>
        dispatch(
          setResults({
            process: 'Staging tickets from database',
            results: 'Success!'
          })
        )
    });
  };

  return (
    <GlobalButton onClick={handleStageTicketsFromDb} buttonText={'Stage Tickets from database'} />
  );
}

export default StageTicketsFromDatabaseButton;
