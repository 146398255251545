import React, { useMemo, useState } from 'react';
import { Badge, Button, Modal, Stack, Table } from 'react-bootstrap';
import { CheckSquare, Square } from 'react-bootstrap-icons';
import { setFoundTickets } from '../ReduxSlices/ticketsSlice';
import { DiscoveredTicket, WEFResponse } from '../Types';
import CannotBeFoughtTableBanner from './CannotBeFoughtTableBanner';
import { useAppDispatch, useAppSelector } from '../hooks';
import { customAxiosFetch } from '../helpers/customFetch';

function FoundTicketsListModal({ clientId = null }: { clientId?: string }) {
  const dispatch = useAppDispatch();
  // const allClientsList = useAppSelector((state) => state.clients.allClientsList);
  // const selectedClientId = useAppSelector((state) => state.clients.selectedClientId);
  const foundTickets = useAppSelector((state) => state.tickets.foundTickets);
  const isImport = useAppSelector((state) => state.tickets.foundTickets.isImport);
  function ticketCannotBeFought(ticket: DiscoveredTicket): boolean {
    return (
      (ticket.newTicket &&
        (['HEARING PENDING', 'HEARING HELD-GUILTY', 'HEARING ADJOURNMENT'].includes(
          ticket.violation_status
        ) ||
          ['4', '5'].includes(ticket.summons_number[0]))) ||
      +ticket.interest_amount !== 0
    );
  }

  function getTicketBackground(ticket: DiscoveredTicket): {
    backgroundColor: string;
    color: string;
  } {
    let result = {
      backgroundColor: 'white',
      color: 'black'
    };
    if (selectedListHasTicket(ticket.summons_number)) {
      result = {
        backgroundColor: 'lightgreen',
        color: 'black'
      };
    } else if (!ticket.newTicket) {
      result = {
        backgroundColor: 'lightgrey',
        color: 'black'
      };
    } else if (ticketCannotBeFought(ticket)) {
      result = {
        backgroundColor: 'red',
        color: 'white'
      };
    }
    return result;
  }

  // const delinquent = useMemo(() => {
  //   const foundClient = allClientsList.find((c: Client) => c.id === selectedClientId);
  //   return foundClient ? foundClient.delinquent : false;
  // }, [allClientsList, selectedClientId]);

  const [selectedTickets, setSelectedTickets] = useState<DiscoveredTicket[]>([]);

  const selectedListHasTicket = (ticketSummonsNumber: string) =>
    selectedTickets.find((ticket) => ticket.summons_number === ticketSummonsNumber);

  const totalFoundTickets = useMemo(() => {
    let total = 0;
    Object.keys(foundTickets.OpenDataTickets).forEach((key) => {
      total += foundTickets.OpenDataTickets[key].length;
    });
    return total;
  }, [foundTickets.OpenDataTickets]);

  const handleTicketClick = (clickedTicket: DiscoveredTicket) => {
    setSelectedTickets((oldTicketList) =>
      selectedListHasTicket(clickedTicket.summons_number)
        ? oldTicketList.filter((x) => x.summons_number !== clickedTicket.summons_number)
        : [...oldTicketList, clickedTicket]
    );
  };

  const handleAddTickets = async () => {
    await customAxiosFetch<WEFResponse<string>>({
      endpoint: '/Estimate/GenerateEstimate',
      config: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          tickets: selectedTickets,
          clientId: clientId
        })
      },
      processDescription: 'Generating Estimate'
    });
    dispatch(setFoundTickets({ OpenDataTickets: [], isImport: false }));
  };

  const handleImportTickets = async () => {
    await customAxiosFetch<WEFResponse<string>>({
      endpoint: '/Estimate/ImportTickets',
      config: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          tickets: selectedTickets,
          clientId: clientId
        })
      },
      processDescription: 'Importing Tickets'
    });
    dispatch(setFoundTickets({ OpenDataTickets: [], isImport: false }));
  };

  return (
    <Modal size="xl" show={!!Object.keys(foundTickets.OpenDataTickets)[0]}>
      <Modal.Header>
        {clientId
          ? `${selectedTickets.length} of ${totalFoundTickets} tickets selected}`
          : totalFoundTickets + ' tickets found'}
      </Modal.Header>
      <Modal.Body>
        <Table bordered size="sm">
          <thead>
            <tr>
              {clientId && <th>Select</th>}
              <th>Plate</th>
              <th>Summons #</th>
              <th>Issued</th>
              <th>State</th>
              <th>Violation</th>
              <th>Violation Status</th>
              <th>Interest</th>
              <th>Amount Due</th>
            </tr>
          </thead>

          {Object.keys(foundTickets.OpenDataTickets).map((key) => (
            <tbody key={'plate-table-section-' + key}>
              <tr>
                <td style={{ border: 'none' }} />
              </tr>
              {[...foundTickets.OpenDataTickets[key]]
                .sort((a: DiscoveredTicket, b: DiscoveredTicket) =>
                  a.newTicket !== b.newTicket
                    ? a.newTicket
                      ? -1
                      : 1
                    : new Date(a.issue_date).valueOf() - new Date(b.issue_date).valueOf()
                )
                .map((ticket: DiscoveredTicket) => (
                  <React.Fragment key={ticket.summons_number}>
                    <tr
                      style={{
                        ...getTicketBackground(ticket)
                      }}>
                      {clientId && (
                        <td className="text-center">
                          {ticket.newTicket ? (
                            selectedListHasTicket(ticket.summons_number) ? (
                              <CheckSquare onClick={() => handleTicketClick(ticket)} />
                            ) : (
                              <Square onClick={() => handleTicketClick(ticket)} />
                            )
                          ) : null}
                        </td>
                      )}
                      <td>{ticket.plate}</td>
                      <td>{ticket.summons_number}</td>
                      <td>{ticket.issue_date}</td>
                      <td>{ticket.state}</td>
                      <td>{ticket.violation}</td>
                      <td>{ticket.violation_status}</td>
                      <td>${ticket.interest_amount}</td>
                      <td>${ticket.amount_due}</td>
                      {clientId && <td>{ticket.newTicket && <Badge bg="primary">New</Badge>}</td>}
                    </tr>
                    {ticketCannotBeFought(ticket) && (
                      <CannotBeFoughtTableBanner
                        reason={
                          ['4', '5'].includes(ticket.summons_number[0]) ? 'Camera Ticket' : null
                        }
                      />
                    )}
                  </React.Fragment>
                ))}
            </tbody>
          ))}
        </Table>
      </Modal.Body>
      <Modal.Footer>
        {clientId ? (
          <Stack direction="horizontal" gap={2}>
            <Button
              variant="secondary"
              onClick={() => {
                setSelectedTickets([]);
                dispatch(setFoundTickets({ OpenDataTickets: [], isImport: false }));
              }}>
              Cancel
            </Button>
            {!isImport && <Button onClick={handleAddTickets}>Fight these tickets!</Button>}
            {isImport && <Button onClick={handleImportTickets}>Import these tickets!</Button>}
          </Stack>
        ) : (
          <Button
            onClick={() => dispatch(setFoundTickets({ OpenDataTickets: [], isImport: false }))}>
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default FoundTicketsListModal;
