import React from 'react';
import Table from 'react-bootstrap/Table';
import ClientSummary from './ClientSummary';
import { useAppSelector } from '../hooks';

function ClientsList() {
  const allClientsList = useAppSelector((state) => state.clients.allClientsList);
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email Address</th>
          <th>FreshBooks ID</th>
          <th>Eligible For Towing</th>
        </tr>
      </thead>
      <tbody>
        {allClientsList?.map((clientObject) => (
          <ClientSummary key={clientObject.id} client={clientObject} />
        ))}
      </tbody>
    </Table>
  );
}

export default ClientsList;
