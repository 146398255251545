import React, { useState } from 'react';
import { Button, Form, Spinner, Stack } from 'react-bootstrap';
import { Printer } from 'react-bootstrap-icons';
import { usePrintTickets } from '../mutations/ticket.mutation';
import { AxiosError } from 'axios';

function DownloadTicketBySummonsNumber() {
  const { mutate: printTickets, isLoading } = usePrintTickets();
  const [summonsNumber, setSummonsNumber] = useState('');

  const handleSubmit = async (e) => {
    e && e.preventDefault();

    if (summonsNumber.trim().length === 0) return alert('Please enter a valid Summons Number');
    const summonsNumberArray = summonsNumber.split(',').map((s) => s.trim());
    printTickets(summonsNumberArray, {
      onSuccess: (data) => {
        setSummonsNumber('');
      },
      onError: (error) => {
        let errorMessage = 'Error while downloading ticket(s), Please try again later.';
        if (error instanceof AxiosError) {
          // errorMessage = error.response?.data?.message;
        }
        alert(errorMessage);
      }
    });
  };
  return (
    <Form onSubmit={handleSubmit} style={{ marginBottom: 15 }}>
      <Form.Text style={{ whiteSpace: 'pre-wrap' }}>
        Download a Ticket by Summons Number. This will NOT remove the ticket from the Print Queue
      </Form.Text>
      <Stack direction="horizontal" className="justify-content-between" gap={5}>
        <Form.Control
          type="text"
          placeholder="Summons Number"
          multiple
          value={summonsNumber}
          onChange={(e) => setSummonsNumber(e.target.value)}
        />
        <Button type="submit">
          {isLoading ? <Spinner animation="border" role="status" size="sm" /> : <Printer />}
        </Button>
      </Stack>
    </Form>
  );
}

export default DownloadTicketBySummonsNumber;
