import React from 'react';
import { setResults } from '../../ReduxSlices/resultsSlice';
import GlobalButton from './GlobalButton';
import { useAppDispatch } from '../../hooks';
import { customFetch } from '../../helpers/customFetch';

function StageTicketsButton() {
  const dispatch = useAppDispatch();

  const handleStageTickets = () => {
    customFetch({
      endpoint: '/Invoice/StageTickets',
      config: { method: 'POST' },
      processDescription: 'Staging tickets',
      skipParseBody: true,
      callback: () =>
        dispatch(
          setResults({
            process: 'Staging tickets',
            results: 'Success!'
          })
        )
    });
  };

  return <GlobalButton onClick={handleStageTickets} buttonText={'Stage Tickets'} />;
}

export default StageTicketsButton;
