import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { useAuth0 } from '@auth0/auth0-react';
import WelcomeText from '../Components/WelcomeText';
// import UnauthorizedNotice from '../Containers/UnauthorizedNotice';
import { useMatchRoute, useNavigate } from '@tanstack/react-location';

function LoginToAuth0Page() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const matchRoute = useMatchRoute();
  const navigate = useNavigate();

  useEffect(() => {
    if (matchRoute('/login') && isAuthenticated) {
      // navigate({
      //   to: '/home',
      //   replace: true
      // });
    }
  }, [isAuthenticated, matchRoute, navigate]);
  return (
    <>
      {/* <UnauthorizedNotice /> */}
      <Container style={{}}>
        <div
          style={{
            marginTop: '10%',
            border: '3px solid black',
            borderRadius: 35,
            padding: '5%'
          }}>
          <WelcomeText />
          <Button className="mt-3" variant="primary" onClick={() => loginWithRedirect()}>
            Log In with Auth0
          </Button>
        </div>
      </Container>
    </>
  );
}

export default LoginToAuth0Page;
