import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import JobDetailsModal from './JobsDetailsModal';
import { PaginatedJobs, Job } from '../../Types';

interface IProps {
  jobs: Job[];
}

function JobsList({ jobs }: IProps) {
  const [selectedJob, setSelectedJob] = useState(null);

  function handleSelectedJob(job: Job) {
    setSelectedJob(job);
  }

  function handleCloseModal() {
    setSelectedJob(null);
  }

  return (
    <div className="mt-2">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>JobType</th>
            <th>Success</th>
            <th>StartTime</th>
            <th>EndTime</th>
          </tr>
        </thead>
        <tbody style={{ cursor: 'pointer' }}>
          {jobs.map((job) => (
            <tr key={job.id} onClick={() => handleSelectedJob(job)}>
              <td>{job.jobType}</td>
              <td>{job.success}</td>
              <td>{job.startTime}</td>
              <td>{job.endTime}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {selectedJob && <JobDetailsModal onCloseModal={handleCloseModal} selectedJob={selectedJob} />}
    </div>
  );
}

export default JobsList;
