import Axios, { CreateAxiosDefaults } from 'axios';
import { store } from '../Store';
import { getAuthorizedUrl } from '../requests/auth.request';
import toast from 'react-hot-toast';
import { showToastMessage } from '../helpers/utils';
import { clearConfigSlice } from '../ReduxSlices/configSlice';

const options: CreateAxiosDefaults = {
  baseURL: window._env_.REACT_APP_WEF_API_BASEURL,
  timeout: 600000,
  headers: {
    'Content-Type': 'application/json'
  }
};

const api = Axios.create(options);

api.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const token = store.getState().config.auth0BearerToken;
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  async (response) => {
    if (response?.data?.errorType === 1) {
      //redirect to freshbooks
      const data = await getAuthorizedUrl();
      window.open(data.data, '_blank');
    }
    if (response?.data?.errors?.length > 0) {
      const formattedErrors = response?.data?.errors?.join('\n');
      showToastMessage('error', 'An error occurred', formattedErrors);
    } else if (response?.data?.success === true) {
      toast.success('Request successfully processed');
    }
    return response;
  },
  async (error) => {
    if (error.response.data?.errorType === 1) {
      //redirect to freshbooks
      const data = await getAuthorizedUrl();
      window.open(data.data, '_blank');
    }
    if (error.response.data?.errors?.length > 0) {
      const formattedErrors = error.response.data?.errors?.join('\n');
      showToastMessage('error', 'An error occurred', formattedErrors);
    } else if (error?.response?.status === 403) {
      toast.error('You do not have permission to access this.');
    } else if (error?.response?.status === 401) {
      store.dispatch(clearConfigSlice());
    } else {
      showToastMessage('error', 'An error occurred', 'Request not processed successfully.');
    }
    return Promise.reject(error);
  }
);

export default api;
