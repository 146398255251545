import { toast } from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import {
  checkAuth,
  getAccessTokenByCode,
  getAuthorizedUrl,
  getRefreshToken,
  revokeToken
} from '../requests/auth.request';

export const useAuthorizedUrlMutation = () => {
  return useMutation({
    mutationFn: async () => await getAuthorizedUrl(),
    onSuccess: () => {
      toast.success('Authorized URL successfully retrieved', {
        icon: '🔑',
        id: 'useAuthorizedUrl'
      });
    },
    onError: () => {
      toast.error('Unable to retrieve Authorized URL', {
        icon: '❌',
        id: 'useAuthorizedUrl'
      });
    }
  });
};

export const useAccessTokenByCodeMutation = () => {
  return useMutation({
    mutationFn: async (code: string) => await getAccessTokenByCode(code),
    onSuccess: () => {
      toast.success('Access Code successfully retrieved', {
        icon: '🔑',
        id: 'useAccessCode'
      });
    },
    onError: () => {
      toast.error('Unable to retrieve Access Code', {
        icon: '❌',
        id: 'useAccessCode'
      });
    }
  });
};

export const useRefreshTokenMutation = () => {
  return useMutation({
    mutationFn: async () => await getRefreshToken(),
    onSuccess: () => {
      toast.success('Refresh Token successfully retrieved', {
        icon: '🔑',
        id: 'useRefreshToken'
      });
    },
    onError: () => {
      toast.error('Unable to retrieve Refresh Token', {
        icon: '❌',
        id: 'useRefreshToken'
      });
    }
  });
};

export const useRevokeTokenMutation = () => {
  return useMutation({
    mutationFn: async () => await revokeToken(),
    onSuccess: () => {
      toast.success('Token successfully revoked', {
        icon: '🔑',
        id: 'useRevokeToken'
      });
    },
    onError: () => {
      toast.error('Unable to revoke token', {
        icon: '❌',
        id: 'useRevokeToken'
      });
    }
  });
};

export const useAuthCheckMutation = () => {
  return useMutation({
    mutationFn: async () => await checkAuth()
  });
};
