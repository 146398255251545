import api from '../configs/api.config';
import { PrintTicketsRequestType, TicketSearchApiResponseType } from '../schemas/ticket.schema';

export const printTickets = async (summons: PrintTicketsRequestType) => {
  const response = await api.post('/printTickets', summons, {
    responseType: 'blob'
  });
  return response.data;
};

export const uploadBulkIgnoredTickets = async (formData: FormData) => {
  const response = await api.post('/ticket/upload/bulk-ignored-tickets', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

export interface ISearchTicketRequest {
  summonsNumber?: string;
  clientId?: string;
  insertedStartDate?: string;
  insertedEndDate?: string;
  settled?: boolean;
  plateId?: string;
  plateNumber?: string;
  pageNumber: number;
}

export const searchTickets = async (payload: ISearchTicketRequest) => {
  const { data } = await api.post<TicketSearchApiResponseType>('/Ticket/get-tickets', payload);
  return data;
};

export const getClientDelinquentTickets = async (clientId: string) => {
  const { data } = await api.get<{ totalDelinquentTickets: number }>(
    `/Ticket/get-delinquent-tickets-count/${clientId}`,
    {
      timeout: 0
    }
  );
  return data?.totalDelinquentTickets;
};
