import React from 'react';
import { setResults } from '../../ReduxSlices/resultsSlice';
import GlobalButton from './GlobalButton';
import { useAppDispatch } from '../../hooks';
import { customFetch } from '../../helpers/customFetch';

function StageInvoicesButton() {
  const dispatch = useAppDispatch();
  const handleStageInvoices = () => {
    const handleResult = () => {
      dispatch(
        setResults({
          process: 'Staging Invoices',
          results: 'Success!'
        })
      );
    };
    customFetch({
      endpoint: '/Payment/stageInvoices',
      config: { method: 'POST' },
      processDescription: 'Staging Invoices',
      callback: handleResult,
      skipParseBody: true
    });
  };

  return <GlobalButton onClick={handleStageInvoices} buttonText={'Stage Invoices'} />;
}

export default StageInvoicesButton;
