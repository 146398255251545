import React from 'react';
import ToastMessage from '../Shared/ToastMessage';
import toast from 'react-hot-toast';

export const downloadBlob = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const getActiveProps = () => ({
  style: {
    fontWeight: 'bold',
    color: 'white'
  }
});

export const showToastMessage = (type: 'success' | 'error', title: string, body: string) => {
  const toastMessage = <ToastMessage title={title} body={body} />;

  if (type === 'success') {
    toast.success(toastMessage);
  } else {
    toast.error(toastMessage);
  }
};
