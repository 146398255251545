import React, { useEffect } from 'react';
import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import { Client } from '../Types';
import { useGetClientDelinquentTicketsMutation } from '../mutations/ticket.mutation';
import { Spinner } from 'react-bootstrap';

type Props = { client: Client; showDelinquentTicketTab: () => void };

const ClientTags = ({ client, showDelinquentTicketTab }: Props) => {
  const {
    mutate: getClientDelinquentTickets,
    isLoading,
    data: delinquentTicketsCount
  } = useGetClientDelinquentTicketsMutation();

  useEffect(() => {
    (async () => {
      await getClientDelinquentTickets(client.id);
    })();
  }, []);

  return (
    <Stack
      direction="horizontal"
      gap={2}
      style={{
        marginBottom: 5
      }}>
      {client.delinquent && (
        <>
          <Badge bg="danger" pill>
            No Working CC as of: {new Date(client.delinquent).toLocaleDateString()}
          </Badge>
        </>
      )}
      {client.eligibleForTowing && (
        <Badge bg="danger" pill>
          Eligible for Towing
        </Badge>
      )}
      {isLoading && (
        <Badge bg="secondary" pill>
          <Spinner size="sm" animation="border" /> Delinquent tickets
        </Badge>
      )}
      {!isLoading && delinquentTicketsCount > 0 && (
        <Badge
          bg="primary"
          style={{
            cursor: 'pointer'
          }}
          pill
          onClick={showDelinquentTicketTab}>
          {delinquentTicketsCount} Delinquent ticket(s)
        </Badge>
      )}
    </Stack>
  );
};

export default ClientTags;
