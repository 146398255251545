import { Button, Modal } from 'react-bootstrap';
import { Job } from '../../Types';
import React from 'react';

interface Props {
  selectedJob: Job;
  onCloseModal: () => void;
}
const JobDetailsModal = ({ selectedJob, onCloseModal }: Props) => {
  return (
    <Modal show={selectedJob !== null} onHide={onCloseModal}>
      <Modal.Header closeButton onHide={onCloseModal}>
        <Modal.Title>Details</Modal.Title>
      </Modal.Header>
      {selectedJob && (
        <Modal.Body>
          <p>ID: {selectedJob.id}</p>
          <p>JobType: {selectedJob.jobType}</p>
          <p>StartTime: {selectedJob.startTime}</p>
          <p>EndTime: {selectedJob.endTime}</p>
          <p>Success: {selectedJob.success}</p>
          <p>Notes: {selectedJob.notes}</p>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button variant="secondary" onClick={onCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default JobDetailsModal;
