import React, { useCallback } from 'react';
import { Button, type ButtonProps, Modal, Spinner, type ModalProps } from 'react-bootstrap';

interface IConfirmDialogProps extends ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  label: React.ReactNode;
  body: React.ReactNode;
  confirmText: string;
  confirmAction: () => void;
  isLoading?: boolean;
  confirmButtonProps?: ButtonProps;
}

export default function ConfirmDialog(props: IConfirmDialogProps) {
  const {
    isOpen,
    label,
    body,
    confirmText,
    confirmAction,
    handleClose: onClose,
    isLoading = false,
    confirmButtonProps = { variant: 'danger' },
    size = 'lg'
  } = props;

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal
      show={isOpen}
      size={size}
      onHide={handleClose}
      aria-labelledby="confirm-modal-centered"
      centered>
      <Modal.Header closeButton onHide={handleClose}>
        <Modal.Title id="confirm-modal-centered">{label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{body}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button disabled={isLoading} {...confirmButtonProps} onClick={() => confirmAction()}>
          <Spinner size="sm" hidden={!isLoading} animation="border" className="mx-1" />
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
