import { addProcess, deleteProcess, clearConfigSlice } from '../ReduxSlices/configSlice';
import { setResults } from '../ReduxSlices/resultsSlice';

import { store } from '../Store';
import { CustomFetchInput, CustomAxiosInput } from '../Types';
import api from '../configs/api.config';

export const customAxiosFetch = async <T>({
  endpoint,
  processDescription = 'Talking to our servers',
  config = { method: 'GET' },
  callback = () => {}
}: CustomAxiosInput<T>) => {
  const processId = Date.now();
  store.dispatch(addProcess({ id: processId, description: processDescription }));
  config = {
    ...config
  };

  try {
    const { data } = await api<T>(window._env_.REACT_APP_WEF_API_BASEURL + endpoint, config);
    callback(data);
    return;
  } catch (error) {
    //do something
  } finally {
    store.dispatch(deleteProcess(processId));
  }
};

export const customFetch = async <T>({
  endpoint,
  processDescription = 'Talking to our servers',
  callback = () =>
    store.dispatch(
      setResults({
        process: processDescription,
        results: 'Success!'
      })
    ),
  config = {},
  skipParseBody = false
}: CustomFetchInput<T>) => {
  const reduxState = store.getState();
  const processId = Date.now();
  store.dispatch(addProcess({ id: processId, description: processDescription }));
  config = {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${reduxState.config.auth0BearerToken}`
    }
  };
  if (config.body) {
    config.headers = { ...config.headers, 'Content-Type': 'application/json' };
  }
  const res = await fetch(window._env_.REACT_APP_WEF_API_BASEURL + endpoint, config);
  store.dispatch(deleteProcess(processId));

  if (res.status > 399) {
    let text: string;
    if (!res.statusText) {
      text = await res.text();
    } else {
      text = res.statusText;
    }
    if (res.status === 500) {
      store.dispatch(
        setResults({
          process: 'talking to our Servers',
          results: 'Internal Server Error',
          backgroundColor: 'orangered'
        })
      );
    } else if (res.status === 401 || res.status === 403) {
      store.dispatch(clearConfigSlice());
    } else {
      store.dispatch(
        setResults({
          process: processDescription,
          results: 'Error: ' + text,
          backgroundColor: 'orangered'
        })
      );
    }
  } else {
    if (!skipParseBody) {
      const json = (await res.json()) as T;
      callback(json);
    } else {
      callback(res as unknown as T);
    }
  }
};
