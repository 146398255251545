import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { fetchClients } from '../Functions';
import { useAppSelector } from '../hooks';

const ClientPaginationControls = () => {
  const clientState = useAppSelector((state) => state.clients);

  return (
    <Pagination className="justify-content-center">
      <Pagination.First
        disabled={clientState.currentPage === 1}
        onClick={() => fetchClients(1, clientState.searchTerm)}
      />
      <Pagination.Prev
        disabled={clientState.currentPage === 1}
        onClick={() => fetchClients(clientState.currentPage - 1, clientState.searchTerm)}
      />
      <Pagination.Next
        disabled={clientState.currentPage === clientState.numberOfPages}
        onClick={() => {
          fetchClients(clientState.currentPage + 1, clientState.searchTerm);
        }}
      />
      <Pagination.Last
        disabled={clientState.currentPage === clientState.numberOfPages}
        onClick={() => fetchClients(clientState.numberOfPages, clientState.searchTerm)}
      />
    </Pagination>
  );
};

export default ClientPaginationControls;
