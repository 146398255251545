import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InternalTicket, TicketListObject } from '../Types';

interface TicketReduxState {
  foundTickets: TicketListObject;
  ticketsToBePrinted?: InternalTicket[];
}

const initialState: TicketReduxState = {
  foundTickets: { OpenDataTickets: [], isImport: false },
  ticketsToBePrinted: []
};

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState: { ...initialState },
  reducers: {
    setFoundTickets: (state, action: PayloadAction<TicketListObject>) => {
      state.foundTickets = action.payload;
    },
    setTicketsToBePrinted: (state, action) => {
      state.ticketsToBePrinted = action.payload;
    },
    clearTicketsSlice: () => {
      return initialState;
    }
  }
});

export const { setFoundTickets, setTicketsToBePrinted, clearTicketsSlice } = ticketsSlice.actions;

export default ticketsSlice.reducer;
